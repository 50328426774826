import React, { createContext, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import {
  Grid,
  CardHeader,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  GridList,
  GridListTileBar,
  FormControl,
  TextField,
  Hidden,
  RadioGroup,
  FormLabel,
  Radio,
  GridListTile,
  CardContent,
  Card,
  CircularProgress,
  InputAdornment,
  Tooltip,
  Badge,
  Icon,
} from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import {
  BootstrapTooltip,
  format,
  formatNumber,
  format_display,
  format_display_date,
  format_display_fancy_notime,
  format_without_time,
  getGender,
  getTimeInIST,
  openLink,
  titleCase,
  GreenTick,
  format_display_withtime,
  RedCancelIcon,
  diff,
  GreyDot,
  YellowTick,
} from "../Utils";
import { AppContext } from "../AppContextProvider";
import {
  DEPOSITORY,
  GENDER,
  SETTLEMENT_OPTIONS,
  STATUS,
  STATUS_MAP,
  USERTYPES,
} from "../Constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { LeadService } from "../Services/LeadService";
import moment from "moment";
import { MasterService } from "../Services/MasterService";
import CompareIcon from "@material-ui/icons/Compare";
import MyDialog from "../components/MyDialog";
import MarkApprovedDialog from "../components/MarkApprovedDialog";
import MoveBackToStepDialog from "../components/MoveBackToStepDialog";
import Config from "../Config";
import BlueButton, { RedButton } from "../components/ColorButton";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import VideocamIcon from "@material-ui/icons/Videocam";
import SingleDate from "../components/SingleDate";
import ItemSelection from "../components/ItemSelection";
import ItemSelectionAsync from "../components/ItemSelectionAsync";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DownloadIcon from "@material-ui/icons/GetApp";
import ReplayIcon from "@material-ui/icons/Replay";
import TocIcon from "@material-ui/icons/Toc";
import path from "../path";
import ViewLogs from "../components/ViewLogs";
import LeadDocuments from "./LeadDocuments";
import ToolbarBottom from "./ToolbarBottom";
import OtherInformation from "./OtherInformation";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { compact, isEmpty, sortBy } from "lodash";
import { NotificationsPopover } from "../components/Notifications";
import { useRef } from "react";
import { useMemo } from "react";
import { Check, Info, NotInterested, Undo } from "@material-ui/icons";
// import download from 'downloadjs';

export const LeadDialogContext = createContext();

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  appBarBottom: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.white,
  },
  accordionSummary: {
    backgroundColor: theme.palette.white,
    color: theme.palette.white,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  header: {
    fontWeight: 700,
  },
  taskHeader: {
    fontWeight: 700,
    textTransform: "initial",
  },
  gridList: {
    margin: 16,
    width: "90%",
  },
  page: {
    height: "100%",
    backgroundColor: theme.palette.divider,
    overflow: "auto",
    paddingBottom: 100,
  },
  section: {
    margin: 16,
    minHeight: 200,
  },
  avatar: {
    backgroundColor: red[500],
  },
  image: {
    margin: 12,
    position: "relative",
    height: 200,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover,  &Mui-focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.7,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
        marginLeft: 8,
        marginRight: 8,
      },
    },
  },
  imageBackdrop: {
    borderRadius: 8,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(
      0
    )}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageButtonBottom: {
    position: "absolute",
    left: 0,
    right: 0,
    // top: 0,
    // bottom: 0,
    bottom: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  formAutoClass: {
    zIndex: 3,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
    margin: 8,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.white,
  },
  iconT: {
    fontSize: 18,
    marginLeft: 16,
    [theme.breakpoints.down("lg")]: {
      marginTop: 4,
    },
    cursor: "pointer",
  },
  title1: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  subtitle: {
    marginLeft: theme.spacing(1),
  },
  titleBar: {
    fontSize: 14,
  },
  subtitleBar: {
    fontSize: 12,
  },
  grow: {
    flexGrow: 1,
  },
  editBanner: {
    positon: "relative",
    bottom: 0,
    width: "100%",
    backgroundColor: "#BFBFBF",
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  editBannerTitle: {
    flex: 1,
  },
  marginLeft: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getStatus = (status) => {
  if (status === -1) {
    return (
      <Tooltip title={"Not initiated"}>
        <GreyDot />
      </Tooltip>
    );
  }
  if (status === 1) {
    return (
      <Tooltip title={"Success"}>
        <GreenTick />
      </Tooltip>
    );
  }
  if (status === 0) {
    return (
      <Tooltip title={"Failed"}>
        <RedCancelIcon />
      </Tooltip>
    );
  }
  if (status === 2) {
    return (
      <Tooltip title={"Downloaded"}>
        <YellowTick />
      </Tooltip>
    );
  }
  return null;
};

const fieldMargin = "dense";
const fieldSize = "small";
const fieldVariant = "outlined";
export default function ViewLeadDialog(props) {
  const {
    showSnackBar,
    showProgressDialog,
    closeProgressDialog,
    showConfirmDialog,
    closeConfirmDialog,
    showConfirmDialog1D,
    closeConfirmDialog1D,
  } = useContext(AppContext);
  const { id } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const nonMandatoryFields = [
    "middle_name",
    "algo_pattern",
    "intraday_pattern",
    "positional_pattern",
    "investment_pattern",
    "broker_name",
    "nominee_cdsl",
    "nominee_nsdl",
  ];
  const [expandedDetails, setExpandedDetails] = React.useState(true);
  const [expandedOtherDetails, setExpandedOtherDetails] = React.useState(false);
  const [expandedTaskDetails, setExpandedTaskDetails] = React.useState(false);
  const [expandedMFTaskDetails, setExpandedMFTaskDetails] =
    React.useState(false);
  const [expandedProofs, setExpandedProofs] = React.useState(false);
  const [lead, setLead] = React.useState({});
  const [originalLead, setOriginalLead] = React.useState({});
  const [masterSegments, setMasterSegments] = React.useState([]);
  const [proofs, setProofs] = React.useState(new Map());

  const [editMode, setEditMode] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [compareProofs, setCompareProofs] = React.useState(undefined);
  const [showCompareProofs, setShowCompareProofs] = React.useState(false);
  const [segments, setSegments] = React.useState({});
  const [summary, setSummary] = React.useState({});
  const [errorList, setErrorList] = React.useState({});
  const [appBarStyle, setAppBarStyle] = React.useState({});
  const [educationOptions, setEducationOptions] = React.useState([]);
  const [occupationOptions, setOccupationOptions] = React.useState([]);
  const [annualIncomeOptions, setAnnualIncomeOptions] = React.useState([]);
  const [tradingExperienceOptions, settradingExperienceOptions] =
    React.useState([]);
  const [relationshipOptions, setRelationshipOptions] = React.useState([]);

  // const [loading, setLoading] = React.useState(true);
  const [approve, setApprove] = React.useState({ open: false });
  const [approveOptions, setApproveOptions] = React.useState([]);

  const [moveBackToStep, setMoveBackToStep] = React.useState({ open: false });

  const [returnUrl, setReturnURL] = useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setDisabled(true);
    if (props.location && props.location.search) {
      let qString = queryString.parse(props.location.search);
      if (qString.redirectUrl) {
        setReturnURL(qString.redirectUrl);
      }
    }
    setOpen(props.open);
    if (props.open) {
      fetchData();
    }
    return () => {
      setLead({});
      setOriginalLead({});
      setSummary({});
      setApprove({ open: false });
      setCompareProofs(undefined);
      setOpen(false);
    };
  }, [props.open]);

  React.useEffect(() => {
    if (lead) {
      setSegments({
        gender: {
          title: "Gender",
          fields: (
            <FormControl variant={fieldVariant} fullWidth>
              <TextField
                label="Gender"
                margin={fieldMargin}
                InputProps={{
                  readOnly: disabled,
                }}
                value={getGender(lead.gender) || (editMode ? "" : "   ")}
                variant={fieldVariant}
              />
            </FormControl>
          ),
          document_codes: ["aadhaar_front", "aadhaar_back"],
        },
        aadhaar_name: {
          title: "Aadhaar Name",
          fields: (
            <FormControl variant={fieldVariant} fullWidth>
              <TextField
                label="Aadhaar Name"
                margin={fieldMargin}
                InputProps={{
                  readOnly: disabled,
                }}
                value={lead.aadhaar_name || (editMode ? "" : "   ")}
                variant={fieldVariant}
              />
            </FormControl>
          ),
          document_codes: ["aadhaar"],
        },
        pan_name: {
          title: "PAN Name",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="PAN Name"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.pan_name || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["pan"],
        },
        account_holder_name: {
          title: "Account Holder Name",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Account Holder Name"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.account_holder_name || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["bank_proof"],
        },
        aadhaar: {
          title: "Aadhaar",
          fields: (
            <FormControl variant={fieldVariant} fullWidth>
              <TextField
                label="Name"
                margin={fieldMargin}
                InputProps={{
                  readOnly: disabled,
                }}
                value={lead.aadhaar || (editMode ? "" : "   ")}
                variant={fieldVariant}
              />
            </FormControl>
          ),
          document_codes: lead.aadhaar_fetched
            ? ["aadhaar"]
            : ["aadhaar_front", "aadhaar_back"],
        },
        pan: {
          title: "PAN",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="PAN"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.pan || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Date of Birth"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={
                      lead.dob
                        ? moment(lead.dob, format_without_time).format(
                          format_display
                        )
                        : "   "
                    }
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["pan"],
        },
        permanent_address: {
          title: "Permanent Address",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 1"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_addr1 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 2"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_addr2 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 3"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_addr3 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="City"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_city || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="State"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_state || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Country"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_country || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Pincode"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.permanent_pincode || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["aadhaar", "aadhaar_front", "aadhaar_back"],
        },
        current_address: {
          title: "Current Address",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 1"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_addr1 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 2"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_addr2 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 3"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_addr3 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="City"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_city || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="State"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_state || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Country"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_country || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Pincode"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.current_pincode || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["current_address"],
        },
        relationship: {
          title: "Dependent Proof",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Relationship"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={
                      lead.relationship
                        ? lead.relationship.label || "  "
                        : "   "
                    }
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["relationship"],
        },
        bank: {
          title: "Bank Proof",
          fields: (
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Account Type"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_account_type || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Account Number"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_account_no || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="IFSC"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_ifsc || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Account Holder Name"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.account_holder_name || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="MICR"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_micr || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Bank name"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_name || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 1"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_addr1 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 2"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_addr2 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Address Line 3"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_addr3 || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="City"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_city || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="State"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_state || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Country"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_country || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant={fieldVariant} fullWidth>
                  <TextField
                    label="Pincode"
                    margin={fieldMargin}
                    InputProps={{
                      readOnly: disabled,
                    }}
                    value={lead.bank_pincode || (editMode ? "" : "   ")}
                    variant={fieldVariant}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ),
          document_codes: ["bank_proof"],
        },
        nominee0: {
          title: "Nominee 1",
          fields: getNomineeCompare(0),
          document_codes: ["nominee_1"],
        },
        nominee1: {
          title: "Nominee 2",
          fields: getNomineeCompare(1),
          document_codes: ["nominee_2"],
        },
        nominee2: {
          title: "Nominee 3",
          fields: getNomineeCompare(2),
          document_codes: ["nominee_3"],
        },
        guardian0: {
          title: "Guardian 1",
          fields: getGuardianCompare(0),
          document_codes: ["guardian_1"],
        },
        guardian1: {
          title: "Guardian 2",
          fields: getGuardianCompare(1),
          document_codes: ["guardian_2"],
        },
        guardian2: {
          title: "Guardian 3",
          fields: getGuardianCompare(2),
          document_codes: ["guardian_3"],
        },
      });
    }
    return () => {
      setSegments({});
    };
  }, [lead.lead_id]);

  const getNomineeCompare = (index) => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Name"
              margin={fieldMargin}
              InputProps={{
                readOnly: true,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].name) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Relationship"
              margin={fieldMargin}
              InputProps={{
                readOnly: true,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].relationship) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        {lead &&
          lead.nominees &&
          lead.nominees[index] &&
          !lead.nominees[index].major && (
            <Grid item>
              <FormControl variant={fieldVariant} fullWidth>
                <TextField
                  label="Date of Birth"
                  margin={fieldMargin}
                  name="dob"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    lead &&
                      lead.nominees &&
                      lead.nominees[index] &&
                      lead.nominees[index].dob
                      ? moment(
                        lead.nominees[index].dob,
                        format_without_time
                      ).format(format_display)
                      : "   "
                  }
                  aria-describedby="outlined-weight-helper-text"
                  variant={fieldVariant}
                />
              </FormControl>
            </Grid>
          )}
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Address Line 1"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].addr1) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Address Line 2"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].addr2) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Address Line 3"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].addr3) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="City"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].city) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="State"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].state) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Country"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].country) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Pincode"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].pincode) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const getGuardianCompare = (index) => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Name"
              margin={fieldMargin}
              InputProps={{
                readOnly: true,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_name) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Relationship"
              margin={fieldMargin}
              InputProps={{
                readOnly: true,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_relationship) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Address Line 1"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_addr1) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Address Line 2"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_addr2) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Address Line 3"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_addr3) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="City"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_city) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="State"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_state) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Country"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_country) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant={fieldVariant} fullWidth>
            <TextField
              label="Pincode"
              margin={fieldMargin}
              InputProps={{
                readOnly: disabled,
              }}
              value={
                (lead &&
                  lead.nominees &&
                  lead.nominees[index] &&
                  lead.nominees[index].guardian_pincode) ||
                "   "
              }
              variant={fieldVariant}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const fetchData = () => {
    // setLoading(true);
    showProgressDialog("Loading...");
    Promise.all([
      LeadService.getLeadById(id, true, true, false, false, false),
      MasterService.getSegments(),
      MasterService.getDropdownOptions("approve"),
      MasterService.getDropdownOptions("reject"),
    ])
      .then((datas) => {
        console.log(datas);
        if (datas[0]) {
          let lead = datas[0];
          let status = STATUS_MAP.find((s) => s.value === lead.status);
          setAppBarStyle({
            paddingRight: 0,
            backgroundColor: status.color,
            color: status.textColor || "white",
          });
          setLead(lead);
          setOriginalLead(lead);
          if (lead.documents) {
            let m = new Map();
            lead.documents.forEach((doc) => {
              m.set(doc.document_code, doc);
            });
            setProofs(m);
          }

          let summary = {};

          switch (lead.status) {
            case STATUS.ONGOING:
              summary.title = `Current Step: ${lead.current_step}`;
              summary.subtitle = lead.modified_date
                ? `Last Updated: ${getTimeInIST(lead.modified_date).format(
                  format_display_fancy_notime
                )}`
                : "";
              summary.btnDelete = lead.can_delete || false;
              break;
            case STATUS.APPROVED:
              summary.title = `Approved by ${lead.performed_by_name || "-"}`;
              summary.remarks = (
                <>
                  <div>
                    Remarks:{" "}
                    {lead.remarks ? lead.remarks.split(",").join(", ") : "-"}
                  </div>
                  {lead.comments ? (
                    <div>Other Comments: {lead.comments}</div>
                  ) : null}
                </>
              );
              summary.subtitle = lead.performed_on
                ? getTimeInIST(lead.performed_on).format(format_display_date)
                : "-";
              break;
            case STATUS.ACTIVATED:
              summary.title = `Approved by ${lead.performed_by_name || "-"}`;
              summary.remarks = (
                <>
                  <div>
                    Remarks:{" "}
                    {lead.remarks ? lead.remarks.split(",").join(", ") : "-"}
                  </div>
                  {lead.comments ? (
                    <div>Other Comments: {lead.comments}</div>
                  ) : null}
                </>
              );
              summary.subtitle = lead.performed_on
                ? getTimeInIST(lead.performed_on).format(format_display_date)
                : "-";
              break;
            case STATUS.REJECTED:
              summary.title = `Rejected by ${lead.performed_by_name || "-"}`;
              summary.remarks = (
                <>
                  <div>
                    Remarks:{" "}
                    {lead.remarks ? lead.remarks.split(",").join(", ") : "-"}
                  </div>
                  {lead.comments ? (
                    <div>Other Comments: {lead.comments}</div>
                  ) : null}
                </>
              );
              summary.subtitle = lead.performed_on
                ? getTimeInIST(lead.performed_on).format(format_display_date)
                : "-";
              summary.btnEdit = true;
              break;
            case STATUS.ESIGN_PENDING:
              summary.title =
                lead.retake_ipv === 0 || lead.retake_photo === 0
                  ? "Note: Lead has been requested to Retake Video/Photo"
                  : "";
              summary.subtitle = "";
              summary.btnEdit = true;
              summary.btnReject = true;
              break;
            case STATUS.COMPLETED:
              summary.title = lead.modified_date
                ? `Last Updated: ${getTimeInIST(lead.modified_date).format(
                  format_display_fancy_notime
                )}`
                : "";
              summary.subtitle = "";
              summary.btnApprove = true;
              summary.btnReject = true;
              summary.btnEdit = true;
              break;
            default:
              summary.title =
                lead.retake_ipv === 0 || lead.retake_photo === 0
                  ? "Note: Lead has been requested to Retake Video/Photo"
                  : "";
              summary.subtitle = "";
          }
          setSummary(summary);
        }
        if (datas[1]) {
          setMasterSegments(datas[1].segments || []);
        }
        if (datas[2] || datas[3]) {
          setApproveOptions({
            approve: (datas[2] && datas[2].approve) || [],
            reject: (datas[3] && datas[3].reject) || [],
          });
        }
        closeProgressDialog();
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            handleClose();
            closeConfirmDialog1D();
          }
        );
        // setLoading(false);
        closeProgressDialog();
      });
  };

  /**
   * Fetch lead data with Editable fields, Fetch masters for Dropdown, etc
   */
  const fetchDataForEdit = () => {
    // setLoading(true);
    showProgressDialog("Loading...");
    Promise.all([
      LeadService.getLeadById(id, true, true, false, false, false, true),
      MasterService.getDropdownOptions("education"),
      MasterService.getDropdownOptions("occupation"),
      MasterService.getDropdownOptions("annual_income"),
      MasterService.getDropdownOptions("trading_experience"),
      MasterService.getDropdownOptions("nominee_relationship"),
    ])
      .then((datas) => {
        console.log(datas);
        if (datas[0]) {
          let lead = datas[0];
          setLead(lead);
          let documents = [];
          if (
            lead.documents &&
            lead.editable_fields &&
            lead.editable_fields.documents
          ) {
            lead.editable_fields.documents.forEach((doc) => {
              let original = lead.documents.find(
                (d) => d.document_code === doc.document_code
              );
              if (original) {
                documents.push({
                  ...doc,
                  dropdown_value: original.dropdown_value,
                  filename: original.filename,
                  url: original.url,
                  type: original.type,
                });
              } else {
                documents.push(doc);
              }
            });
          }
          lead.documents = documents;
        }
        if (datas[1]) {
          setEducationOptions(datas[1].education || []);
        }
        if (datas[2]) {
          setOccupationOptions(datas[2].occupation || []);
        }
        if (datas[3]) {
          setAnnualIncomeOptions(datas[3].annual_income || []);
        }
        if (datas[4]) {
          settradingExperienceOptions(datas[4].trading_experience || []);
        }
        if (datas[5]) {
          setRelationshipOptions(datas[5].nominee_relationship || []);
        }
        setDisabled(false);
        setEditMode(true);
        setExpandedProofs(true);
        setErrorList({
          nominees: lead.nominees.map(() => ({
            relationship: false,
            guardian_relationship: false,
          })),
        });
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            handleClose();
            closeConfirmDialog1D();
          }
        );
      })
      .finally(() => closeProgressDialog());
  };

  const handleClose = (callback = false) => {
    if (editMode) {
      cancelEditMode(() => {
        setEditMode(false);
        setDisabled(true);
        close(callback);
      });
    } else {
      close(hasChanges);
    }
  };

  const close = (callback) => {
    props.onClose(callback);
    setLead({});
    setOriginalLead({});
    setApprove({ open: false });
    setSummary({});
    setAppBarStyle({});
    setOpen(false);
    setDisabled(true);
    setEditMode(false);
    setExpandedDetails(true);
    setExpandedOtherDetails(false);
    setExpandedTaskDetails(false);
    setExpandedProofs(false);
    setCompareProofs(undefined);
    if (returnUrl) {
      history.push(returnUrl);
    }
  };

  const showCompare = (type) => {
    setShowCompareProofs(true);
    setCompareProofs(segments[type]);
  };

  const action = (type) => (e) => {
    if (type === "edit") {
      fetchDataForEdit();
    } else if (type === "approve") {
      setApprove({ open: true, type, title: "Approve" });
    } else if (type === "reject") {
      fetchAndShowReject(type);
    } else if (type === "save") {
      save();
    } else if (type === "discard") {
      cancelEditMode(() => {
        setEditMode(false);
        setDisabled(true);
        setLead(originalLead);
      });
    } else if (type === "status") {
      checkStatus()
    } else if (type === "request_esign") {
      requestESign()
    } else if (type === "photo") {
      requestIPV(type)
    } else if (type === "ipv") {
      requestIPV(type)
    } else if (type === "activate") {
      activateLead()
    } else if (type === "move-to-ongoing") {
      fetchAndShowMoveBackToStep();
    } else if (type === "delete") {
      deleteLead()
    }
  };

  const fetchAndShowMoveBackToStep = () => {
    showProgressDialog("Fetching Steps...");
    LeadService.getMoveToStepList()
      .then((data) => {
        console.log(data);
        if (data && data.steps) {
          setMoveBackToStep({
            open: true,
            steps: sortBy(
              data.steps.map((step) => ({
                ...step,
                step_sequence: parseInt(step.step_sequence),
              })),
              ["step_sequence"]
            ),
          });
        } else {
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            closeConfirmDialog1D();
          }
        );
      })
      .finally(() => closeProgressDialog());
  };

  const fetchAndShowReject = (type) => {
    showProgressDialog("Fetching Details...");
    LeadService.getLeadById(id, false, false, false, false, false, true)
      .then((data) => {
        console.log(data);
        if (data) {
          let lead = data;
          let documents = [];
          if (lead.editable_fields && lead.editable_fields.documents) {
            lead.editable_fields.documents.forEach((doc) => {
              documents.push({
                document_id: doc.document_id,
                document_label: doc.document_label,
              });
            });
          }
          setApprove({
            open: true,
            type,
            title: "Reject",
            documents: documents.length > 0 ? documents : undefined,
          });
        } else {
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            closeConfirmDialog1D();
          }
        );
      })
      .finally(() => closeProgressDialog());
  };

  const save = () => {
    let body = diff(originalLead, lead);
    let documents = lead.documents
      ? [...lead.documents.filter((d) => d.has_modifications)]
      : [];
    let nominees = lead.nominees.map((n, i) => {
      const delta = diff(originalLead.nominees[i], n);
      if (Object.keys(delta).length === 0 && delta.constructor === Object) {
        return false;
      }
      return { nominee_id: n.nominee_id, ...delta };
    });

    if (
      !lead.editable_fields.lead.includes("current_addr1") ||
      !("current_addr_same" in body)
    ) {
      delete body.current_addr1;
      delete body.current_addr2;
      delete body.current_addr3;
      delete body.current_city;
      delete body.current_state;
      delete body.current_country;
      delete body.current_pincode;
    }
    delete body.documents;
    delete body.additional_documents;
    delete body.payment_details;
    delete body.editable_fields;
    delete body.segment;
    delete body.nominees;
    delete body.activation_task_status;
    delete body.mf_activation_task_status;
    body.nominees = compact(nominees);
    if (Object.keys(body).length > 0 || documents.length > 0) {
      if (validate(body, documents)) {
        showConfirmDialog("Confirm changes?", undefined, () => {
          closeConfirmDialog();
          console.log(body);
          showProgressDialog("Updating Lead...");
          (Object.keys(body).length > 0
            ? LeadService.updateLead(lead.lead_id, { lead: body })
            : Promise.resolve({ success: true })
          )
            .then((d) => {
              if (d.success && documents.length > 0) {
                let pL = documents.map((document) => {
                  let fd = new FormData();
                  fd.append("file", document.file);
                  fd.append("document_code", document.document_code);
                  if (document.is_dropdown) {
                    fd.append("dropdown_value", document.dropdown_value);
                  }
                  return LeadService.updateLeadDocuments(lead.lead_id, fd);
                });
                return Promise.all(pL);
              } else if (!d.success) {
                //mark Fields as error
                if (d.invalid_values && d.invalid_values.length > 0) {
                  markError(d.invalid_values);
                }
                return Promise.reject();
              } else {
                return Promise.resolve([]);
              }
            })
            .then((responses) => {
              if (responses.length > 0) {
                if (responses.filter((d) => !d.success).length === 0) {
                  const body = {
                    document_codes: documents.map((d) => d.document_code),
                    is_rejected:
                      responses.filter((r) => r.is_rejected).length > 0,
                  };
                  return LeadService.confirmLeadDocuments(lead.lead_id, body)
                    .then((data) => {
                      if (data.success) {
                        return Promise.resolve({ success: true, responses });
                      } else {
                        return Promise.reject();
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      return Promise.reject();
                    });
                } else {
                  let dcList = [];
                  let isRejected = false;
                  responses.forEach((res, i) => {
                    if (res.success) {
                      dcList.push(documents[i].document_code);
                      if (res.is_rejected) {
                        isRejected = true;
                      }
                    }
                  });
                  if (!isEmpty(dcList)) {
                    const body = {
                      document_codes: dcList,
                      is_rejected: isRejected, // responses.filter(r => r.is_rejected).length > 0
                    };
                    return LeadService.confirmLeadDocuments(lead.lead_id, body)
                      .then((data) => {
                        if (data.success) {
                          return Promise.resolve({ success: false, responses });
                        } else {
                          return Promise.reject();
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        return Promise.reject();
                      });
                  } else {
                    return Promise.resolve({ success: false, responses });
                  }
                }
              } else {
                return Promise.resolve({ success: true, responses });
              }
            })
            .then((data) => {
              if (data.success) {
                setEditMode(false);
                setDisabled(true);
                fetchData();
                showSnackBar("Saved", "success");
              } else {
                data.responses.forEach((error, i) => {
                  if (error.show_error) {
                    documents[i].error = error.error;
                  }
                });
                let d = lead.documents;
                documents.forEach((doc) => {
                  const dd = d.find(
                    (d) => d.document_code === doc.document_code
                  );
                  dd.error = doc.error;
                });
                setLead((lead) => ({ ...lead, documents: d }));
                showSnackBar(
                  "Please check the form for errors and try again",
                  "error"
                );
              }
            })
            .catch((error) => {
              console.error(error);
              showConfirmDialog1D(
                "Something went wrong. Please try again.",
                undefined,
                () => {
                  closeConfirmDialog1D();
                }
              );
            })
            .finally(() => closeProgressDialog());
        });
      } else {
        showSnackBar(
          "Please make sure all required fields have values and required documents are uploaded.",
          "error"
        );
      }
    } else {
      showSnackBar("No Changes Made", "error");
    }
  };

  const markError = (fields) => {
    let key;
    for (key in fields) {
      errorList[key] = true;
    }
    setErrorList(errorList);
  };

  const validate = (body, documents) => {
    let valid = true;

    //Validate Fields
    let key;
    let errorKeys = [];
    for (key in body) {
      if (
        !nonMandatoryFields.includes(key) &&
        (body[key] === null || body[key] === undefined) &&
        ((typeof body[key] === "string" && body[key].trim().length === 0) ||
          (typeof body[key] === "number" && body[key] >= 0) ||
          typeof body[key] === "undefined")
      ) {
        valid = false;
        errorKeys.push(key);
      }
    }

    for (key in errorList) {
      errorList[key] = false;
    }
    errorKeys.forEach((k) => (errorList[key] = true));
    setErrorList(errorList);

    //Validate Documents
    if (documents) {
      documents.forEach((doc) => {
        if (
          doc.is_required &&
          ((doc.is_dropdown && !doc.dropdown_value) || !doc.file)
        ) {
          let docs = lead.documents;
          let d = docs.find((d) => d.document_code === doc.document_code);
          if (d) {
            d.is_error = true;
          }
          setLead((lead) => ({ ...lead, documents: docs }));
          valid = false;
        }
      });
    }
    return valid;
  };

  const cancelEditMode = (callback) => {
    showConfirmDialog(
      "Confirm Cancel. Any changes made will be lost?",
      undefined,
      () => {
        closeConfirmDialog();
        if (callback) {
          callback();
        }
      }
    );
  };

  const checkStatus = () => {
    showProgressDialog("Checking Status");
    LeadService.checkStatus(lead.lead_id)
      .then((d) => {
        if (d.success && d.verified) {
          fetchData();
        } else if (d.message) {
          showSnackBar(d.message, "info");
        } else if (d.error) {
          showSnackBar(d.error, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        showConfirmDialog1D(
          "Something went wrong. Please try again.",
          undefined,
          () => {
            closeConfirmDialog1D();
          }
        );
      })
      .finally(() => closeProgressDialog());
  };

  const requestESign = () => {
    showConfirmDialog("Confirm Request E-Sign?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Requesting...");
      LeadService.requestESign(lead.lead_id)
        .then((data) => {
          if (data.success) {
            showSnackBar("Requested", "info");
            fetchData();
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const activateLead = () => {
    showConfirmDialog("Confirm?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Activating...");
      LeadService.activateLead({ field: "lead_id", value: lead.lead_id })
        .then((data) => {
          if (data.success) {
            if (data.popup_message) {
              showConfirmDialog1D(data.popup_message, undefined, () => {
                closeConfirmDialog1D();
              });
            } else {
              showSnackBar("Activated", "info");
            }
            fetchData();
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const requestIPV = (type) => {
    showConfirmDialog(`Confirm Request ${titleCase(type)}?`, undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Requesting...");
      LeadService.requestIPV(lead.lead_id, type)
        .then((data) => {
          if (data.success) {
            showSnackBar("Requested", "info");
            fetchData();
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const deleteLead = () => {
    showConfirmDialog(`Confirm Deletion of lead with mobile ${lead.mobile}?`, undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Deleting...");
      LeadService.deleteLead(lead.lead_id)
        .then((data) => {
          if (data.success) {
            showSnackBar(data.message, "info");
            handleClose()
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const markApproved = (remarks, documents, comments) => {
    showConfirmDialog("Confirm changes?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Saving...");
      let body = {
        lead_id: lead.lead_id,
        status: approve.type === "approve" ? STATUS.APPROVED : STATUS.REJECTED,
        remarks: remarks || "",
        comments,
        documents: documents || [],
      };
      LeadService.updateLeadStatus(body)
        .then((data) => {
          if (data.success) {
            showSnackBar("Saved", "info");
            fetchData();
            setApprove({ open: false });
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const moveToOngoing = (step) => {
    showConfirmDialog("Confirm changes?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Saving...");
      let body = {
        lead_id: lead.lead_id,
        step_name: step,
      };
      LeadService.moveLeadBackToOngoing(body)
        .then((data) => {
          if (data.success) {
            showSnackBar("Saved", "info");
            fetchData();
            setMoveBackToStep({ open: false, steps: [] });
            setHasChanges(true);
          } else {
            showSnackBar("Something went wrong. Please try again.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const downloadAllDocs = () => {
    showProgressDialog("Requesting Download...");
    LeadService.downloadDocuments(lead.lead_id)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${lead.client_code || ""}${lead.client_code ? " - " : ""}${lead.pan
          }.zip`
        );
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
        showSnackBar("Failed to Download. Please try again", "error");
      })
      .finally(() => {
        closeProgressDialog();
      });
  };

  const uploadAdditionalDoc = (type, file) => {
    showProgressDialog("Uploading...");
    let fd = new FormData();
    fd.append("file", file);
    fd.append("dropdown_id", type);
    LeadService.updateAdditionalDocuments(lead.lead_id, fd)
      .then((data) => {
        if (data.success) {
          showSnackBar("File uploaded", "success");
          fetchData();
        } else {
          showSnackBar(data.error, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        showSnackBar("Failed to Download. Please try again", "error");
      })
      .finally(() => {
        closeProgressDialog();
      });
  };

  const deleteAdditionalDoc = (docId) => {
    showConfirmDialog("Confirm Remove?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Removing document...");
      LeadService.removeAdditionalDocuments(docId)
        .then(() => {
          showSnackBar("Removed", "success");
          fetchData();
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const requestOption = (option) => {
    if (option === "esign") {
      showConfirmDialog("Confirm?", undefined, () => {
        closeConfirmDialog();
        showProgressDialog("Requesting...");
        const body = {
          lead_id: lead.lead_id,
          status: STATUS.ESIGN_PENDING,
          sign_type: option,
        };
        LeadService.updateLeadStatus(body)
          .then((data) => {
            if (data.success) {
              showSnackBar("Requested E-Sign", "info");
              fetchData();
            } else {
              showSnackBar("Something went wrong. Please try again.", "error");
            }
          })
          .catch((error) => {
            console.log(error);
            showConfirmDialog1D(
              "Something went wrong. Please try again.",
              undefined,
              () => {
                closeConfirmDialog1D();
              }
            );
          })
          .finally(() => closeProgressDialog());
      });
    }
  };

  return (
    <LeadDialogContext.Provider
      value={{
        close,
        expandedDetails,
        setExpandedDetails,
        expandedProofs,
        setExpandedProofs,
        expandedOtherDetails,
        setExpandedOtherDetails,
        expandedTaskDetails,
        setExpandedTaskDetails,
        expandedMFTaskDetails,
        setExpandedMFTaskDetails,
        downloadAllDocs,
        deleteAdditionalDoc,
        uploadAdditionalDoc,
        appBarStyle,
        errorList,
        setErrorList,
        handleClose,
        tradingExperienceOptions,
        fieldSize,
        action,
        lead,
        setLead,
        originalLead,
        disabled,
        masterSegments,
        proofs,
        editMode,
        compareProofs,
        showCompare,
        showCompareProofs,
        segments,
        summary,
        educationOptions,
        occupationOptions,
        annualIncomeOptions,
        relationshipOptions,
        requestOption,
        fetchData,
      }}
    >
      <Dialog
        fullScreen
        open={open}
        disableEscapeKeyDown={editMode}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarTop />
        <Grid
          container
          className={classes.page}
          style={
            lead.status && lead.status === STATUS.CANCELLED
              ? { backgroundColor: lead.status }
              : {}
          }
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            container
            item
            spacing={1}
            xs={12}
            lg={12}
            xl={10}
            justify={"center"}
            direction="row"
            style={{ marginTop: 16 }}
          >
            {segments && lead.lead_id && (
              <Grid item xs={12} lg={12}>
                <LeadInformation />
              </Grid>
            )}
            {lead.lead_id && (
              <Grid item xs={12} lg={12}>
                <LeadDocuments />
              </Grid>
            )}
            {lead.lead_id && (
              <Grid item xs={12} lg={12}>
                <OtherInformation />
              </Grid>
            )}
            {lead.lead_id &&
              (lead.status === STATUS.APPROVED ||
                lead.status === STATUS.ACTIVATED) &&
              lead.activation_task_status &&
              lead.activation_task_status.length > 0 && (
                <Grid item xs={12} lg={12}>
                  <TaskStatus />
                </Grid>
              )}
            {lead.lead_id &&
              (lead.status === STATUS.APPROVED ||
                lead.status === STATUS.ACTIVATED) &&
              lead.mf_activation_task_status &&
              lead.mf_activation_task_status.length > 0 && (
                <Grid item xs={12} lg={12}>
                  <MFTaskStatus />
                </Grid>
              )}
          </Grid>
        </Grid>
        <AppBar position="fixed" className={classes.appBarBottom}>
          <ToolbarBottom />
        </AppBar>
        <MyDialog
          maxWidth={"lg"}
          fullWidth={true}
          content={
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
                height: "98%",
              }}
            >
              <Grid item xs={12} md={5}>
                {compareProofs && compareProofs.fields}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} md={6}>
                <GridList
                  cellHeight={400}
                  className={classes.gridList}
                  cols={1}
                >
                  {compareProofs &&
                    compareProofs.document_codes &&
                    compareProofs.document_codes.map((doc, key) => {
                      let data =
                        lead.documents &&
                        lead.documents.find((d) => d.document_code === doc);
                      return data ? (
                        <GridListTile key={key}>
                          <embed
                            style={{ width: "70%" }}
                            height="400"
                            name="plugin"
                            src={data.url}
                            type={data.type}
                          />
                          <GridListTileBar
                            classes={{
                              title: classes.titleBar,
                              subtitle: classes.subtitleBar,
                            }}
                            title={
                              <>
                                {data.document_label}
                                {data.is_required && <sup>*</sup>}
                              </>
                            }
                            subtitle={data.source || ""}
                          />
                        </GridListTile>
                      ) : (
                        false
                      );
                    })}
                </GridList>
              </Grid>
            </Grid>
          }
          title={"Compare Proofs"}
          subtitle={compareProofs ? compareProofs.title : ""}
          open={showCompareProofs}
          close={() => {
            setCompareProofs(undefined);
            setShowCompareProofs(false);
          }}
        />
        <MarkApprovedDialog
          fullWidth={true}
          maxWidth={"sm"}
          open={approve.open || false}
          type={approve.type}
          title={approve.title || ""}
          documents={approve.documents}
          close={() => {
            setApprove({ open: false });
          }}
          save={(remarks, documents, comments) => {
            markApproved(remarks, documents, comments);
          }}
          required={approve.type === "reject"}
          options={
            approve.type === "approve"
              ? approveOptions.approve
              : approveOptions.reject
          }
        />
        <MoveBackToStepDialog
          fullWidth={true}
          maxWidth={"sm"}
          open={moveBackToStep.open || false}
          steps={moveBackToStep.steps}
          close={() => {
            setMoveBackToStep({ open: false, steps: [] });
          }}
          save={(step) => {
            moveToOngoing(step);
          }}
        />
      </Dialog>
    </LeadDialogContext.Provider>
  );
}

const AppBarTop = () => {
  const { originalLead, action, appBarStyle, handleClose, editMode } =
    useContext(LeadDialogContext);
  const classes = useStyles();

  const notificationsRef = useRef(null);
  const notifications = useMemo(
    () => originalLead.notifications,
    [originalLead.notifications]
  );
  const [open, setOpen] = useState(false);

  return (
    <AppBar className={classes.appBar} color="transparent" style={appBarStyle}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.title}>
          {originalLead.lead_id && originalLead.pan_name
            ? `Lead : ${originalLead.pan_name}`
            : ""}
        </Typography>
        {!isEmpty(notifications) && (
          <Tooltip title="Notifications">
            <IconButton
              color="inherit"
              style={{ marginRight: 16 }}
              ref={notificationsRef}
              onClick={() => setOpen(true)}
            >
              <Badge badgeContent={notifications.length} color={"error"}>
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="h6" color="inherit">
          {typeof originalLead.status === "number"
            ? STATUS_MAP.find((s) => s.value === originalLead.status).label
            : ""}
        </Typography>
      </Toolbar>
      {editMode && (
        <div className={classes.editBanner}>
          <Typography
            className={classes.editBannerTitle}
            variant="h6"
            color="inherit"
          >
            In Edit Mode
          </Typography>
          <div style={{ float: "right" }}>
            <IconButton color="inherit" onClick={action("discard")}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications || []}
        open={open}
        onClose={() => setOpen(false)}
      />
    </AppBar>
  );
};

const TaskStatus = () => {
  // Status
  // -2 - Not required
  // -1 - Not initiated
  //  0 - Failed
  //  1 - Success (API)
  //  2 - Initiated (Downloaded)
  const {
    showProgressDialog,
    closeProgressDialog,
    showSnackBar,
    showConfirmDialog,
    closeConfirmDialog,
  } = useContext(AppContext);
  const { lead, expandedTaskDetails, setExpandedTaskDetails, close } =
    useContext(LeadDialogContext);
  const classes = useStyles();
  const [viewLogsOpen, setViewLogsOpen] = useState(false);
  const [viewLogsData, setViewLogsData] = useState(null);
  const history = useHistory();

  const viewLogs = (title, type, mode) => () => {
    setViewLogsData({
      id: "latest",
      type: type,
      lead_id: lead.lead_id,
      label: `${title} - ${lead && lead.pan}`,
    });
    setViewLogsOpen(true);
  };

  const taskAction = (type, mode, status) => () => {
    showConfirmDialog(
      "Confirm action?",
      undefined,
      () => {
        closeConfirmDialog();
        let httpPromise = null;
        let progressText = null;
        if (mode === "api") {
          progressText = "Requesting...";
          httpPromise = LeadService.attemptTask(lead.lead_id, type, mode);
        } else if (mode === "file_download") {
          progressText = "Downloading...";
          httpPromise = LeadService.attemptTask(lead.lead_id, type, mode);
        } else if (mode === "batch_api" || mode === "batch_download") {
          progressText = "Requesting...";
          if (status === -1) {
            history.push(path.UPLOAD.replace(":type", type));
            close();
          } else {
            httpPromise = LeadService.reAttemptTask(lead.lead_id, type);
          }
        }
        if (httpPromise) {
          showProgressDialog(progressText);
          httpPromise
            .then((data) => {
              if (data.file_url) {
                // download(data.file_url)
                // fetch(data.file_url, {mode: "no-cors"})
                // 	.then(res => res.blob())
                // 	.then(blob => {
                // 		const url = window.URL.createObjectURL(new Blob([blob]));
                // 		// openLink(data.file_url)
                // 		const link = document.createElement('a');
                // 		link.href = url;
                // 		link.target = "_blank";
                // 		link.rel = "noopener noreferrer"
                // 		link.download = data.file_url.substring(data.file_url.lastIndexOf("/") + 1);
                // 		// 3. Append to html page
                // 		document.body.appendChild(link);
                // 		// 4. Force download
                // 		link.click();
                // 		// 5. Clean up and remove the link
                // 		link.parentNode.removeChild(link);
                // 	})
                // openLink(data.file_url)

                const link = document.createElement("a");
                link.href = data.file_url;
                link.target = "_blank";
                link.rel = "noopener noreferrer";
                link.download = data.file_url.substring(
                  data.file_url.lastIndexOf("/") + 1
                );
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
              }
              if (mode === "batch_download") {
                lead.activation_task_status.find(
                  (task) => task.type === type
                ).status = 0;
              } else if (mode === "batch_api") {
                lead.activation_task_status.find(
                  (task) => task.type === type
                ).status = 1;
              } else if (data.file_url && mode === "file_download") {
                lead.activation_task_status.find(
                  (task) => task.type === type
                ).status = 2;
              } else if (mode === "api") {
                lead.activation_task_status.find(
                  (task) => task.type === type
                ).status = data.success ? 1 : 0;
              }
              if (data.message) {
                showSnackBar(data.message, "info");
              } else if (data.error) {
                showSnackBar(data.error, "error");
              }
            })
            .catch((error) => {
              console.log(error);
              showSnackBar("Failed to Download. Please try again", "error");
            })
            .finally(() => {
              closeProgressDialog();
            });
        }
      },
      "Cancel",
      "Confirm"
    );
  };

  const taskStatusRow = (task) => {
    return (
      <Grid
        key={task.type}
        item
        container
        justify="flex-start"
        spacing={2}
        alignItems="center"
        xs={12}
      >
        <Grid item>{getStatus(task.status)}</Grid>
        <Grid item xs={12} lg={3}>
          <Typography variant="body1" className={classes.taskHeader}>
            {task.title}
          </Typography>
        </Grid>
        {task.file_download && [-1, 0, 2].includes(task.status) && (
          <Grid item xs={12} lg={2}>
            <Button
              startIcon={<DownloadIcon />}
              onClick={taskAction(task.type, "file_download", task.status)}
            >
              File Download
            </Button>
          </Grid>
        )}
        {task.api && [-1, 0, 2].includes(task.status) && (
          <Grid item xs={12} lg={2}>
            <Typography color="secondary">
              {task.status === 0 || task.status === 2 ? (
                <Button
                  startIcon={<ReplayIcon />}
                  onClick={taskAction(task.type, "api", task.status)}
                >
                  Reattempt Upload
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onClick={taskAction(task.type, "api", task.status)}
                >
                  Upload
                </Button>
              )}
            </Typography>
          </Grid>
        )}
        {task.batch_api && [-1, 0, 1, 2].includes(task.status) && (
          <Grid item>
            <Typography color="secondary">
              {0 === task.status ? (
                <RedButton startIcon={<ReplayIcon />}>
                  Reattempt requested
                </RedButton>
              ) : [1, 2].includes(task.status) ? (
                <Button
                  startIcon={<ReplayIcon />}
                  onClick={taskAction(task.type, "batch_api", task.status)}
                >
                  Reattempt Batch Upload
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onClick={taskAction(task.type, "batch_api", task.status)}
                >
                  Batch Upload
                </Button>
              )}
            </Typography>
          </Grid>
        )}
        {task.batch_download && [-1, 0, 1, 2].includes(task.status) && (
          <Grid item>
            <Typography color="secondary">
              {0 === task.status ? (
                <RedButton startIcon={<ReplayIcon />}>
                  Reattempt requested
                </RedButton>
              ) : 2 === task.status || 1 === task.status ? (
                <Button
                  startIcon={<ReplayIcon />}
                  onClick={taskAction(task.type, "batch_download", task.status)}
                >
                  Reattempt Batch Download
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onClick={taskAction(task.type, "batch_download", task.status)}
                >
                  Batch Download
                </Button>
              )}
            </Typography>
          </Grid>
        )}
        {task.show_log && [0, 1].includes(task.status) && (
          <Grid item xs={12} lg={2}>
            <Button
              startIcon={<TocIcon />}
              variant="outlined"
              onClick={viewLogs(task.title, task.type, "api")}
            >
              View Logs
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider variant="inset" />
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion
      square
      expanded={expandedTaskDetails}
      onChange={() => setExpandedTaskDetails((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="task-header"
      >
        <CardHeader
          title={`Activation Tasks`}
          titleTypographyProps={{ variant: "h4" }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "100%",
            minHeight: 100,
            marginRight: 16,
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          <Grid container justify="flex-start" spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {lead.activation_task_status
              .filter((task) => task.status !== -2)
              .map((task) => taskStatusRow(task))}
          </Grid>
          <ViewLogs
            id={viewLogsData && viewLogsData.id}
            type={viewLogsData && viewLogsData.type}
            label={viewLogsData && viewLogsData.label}
            lead_id={lead && lead.lead_id}
            open={viewLogsOpen}
            close={() => {
              setViewLogsOpen(false);
              setViewLogsData(null);
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const MFTaskStatus = () => {
  // Status
  // -2 - Not required
  // -1 - Not initiated
  //  0 - Failed
  //  1 - Success (API)
  //  2 - Initiated (Downloaded)

  const {
    showProgressDialog,
    closeProgressDialog,
    showSnackBar,
    showConfirmDialog,
    closeConfirmDialog,
  } = useContext(AppContext);
  const { lead, expandedMFTaskDetails, setExpandedMFTaskDetails } =
    useContext(LeadDialogContext);
  const classes = useStyles();

  const taskAction = (type, status) => () => {
    showConfirmDialog(
      "Confirm action?",
      undefined,
      () => {
        closeConfirmDialog();
        showProgressDialog("Updating...");
        LeadService.attemptMFTask(lead.lead_id, type, status)
          .then((data) => {
            if (data.success) {
              lead.mf_activation_task_status.find(
                (task) => task.type === type
              ).status = status;
            }
            if (data.message) {
              showSnackBar(data.message, "info");
            } else if (data.error) {
              showSnackBar(data.error, "error");
            }
          })
          .catch((error) => {
            console.log(error);
            showSnackBar("Something went wrong. Please try again", "error");
          })
          .finally(() => {
            closeProgressDialog();
          });
      },
      "Cancel",
      "Confirm"
    );
  };

  const taskStatusRow = (task) => {
    return (
      <Grid
        key={task.type}
        item
        container
        justify="flex-start"
        spacing={2}
        alignItems="center"
        xs={12}
      >
        <Grid item>{getStatus(task.status)}</Grid>
        <Grid item xs={12} lg={3}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            <Typography variant="body1" className={classes.taskHeader}>
              {task.title}
            </Typography>
            {task.tooltip ? (
              <BootstrapTooltip title={task.tooltip}>
                <Info
                  fontSize="small"
                  style={{ color: blue[500], marginLeft: 8 }}
                />
              </BootstrapTooltip>
            ) : null}
          </div>
        </Grid>
        {lead.status === STATUS.APPROVED && [-2, 1].includes(task.status) && (
          <Grid item xs={12} lg={2}>
            <Button
              startIcon={<Undo />}
              variant="outlined"
              onClick={taskAction(task.type, -1)}
            >
              Undo
            </Button>
          </Grid>
        )}
        {lead.status === STATUS.APPROVED && task.status === -1 && (
          <Grid container item xs={12} lg={4} spacing={2}>
            <Grid item>
              <Button
                startIcon={<Check />}
                variant="outlined"
                onClick={taskAction(task.type, 1)}
              >
                Mark Complete
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<NotInterested />}
                variant="outlined"
                onClick={taskAction(task.type, -2)}
              >
                Skip
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider variant="inset" />
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion
      square
      expanded={expandedMFTaskDetails}
      onChange={() => setExpandedMFTaskDetails((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="task-header"
      >
        <CardHeader
          title={`Mutual Funds Activation Tasks`}
          titleTypographyProps={{ variant: "h4" }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: "100%",
            minHeight: 100,
            marginRight: 16,
            marginLeft: 16,
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          <Grid container justify="flex-start" spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {lead.mf_activation_task_status.map((task) => taskStatusRow(task))}
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const LeadInformation = () => {
  const {
    expandedDetails,
    setExpandedDetails,
    lead,
    originalLead,
    setLead,
    editMode,
    disabled,
    proofs,
    errorList,
    segments,
    showCompare,
    educationOptions,
    occupationOptions,
    annualIncomeOptions,
    setErrorList,
    tradingExperienceOptions,
    relationshipOptions,
    masterSegments,
    action,
    requestOption,
    fetchData,
  } = useContext(LeadDialogContext);
  const {
    mobileView,
    state,
    showConfirmDialog,
    closeConfirmDialog,
    showProgressDialog,
    closeProgressDialog,
    showSnackBar,
    showConfirmDialog1D,
    closeConfirmDialog1D,
  } = useContext(AppContext);
  const classes = useStyles();
  const [txnLoading, setTxnLoading] = React.useState(false);
  const [showPennydropTransactions, setShowPennydropTransactions] =
    React.useState(false);
  const [transactions, setTransactions] = React.useState([]);

  const updateTracking = () => {
    showConfirmDialog("Confirm Tracking details?", undefined, () => {
      closeConfirmDialog();
      showProgressDialog("Saving...");
      LeadService.updateTrackingDetails({
        lead_id: lead.lead_id,
        tracking_details: lead.tracking_details,
      })
        .then(() => {
          showSnackBar("Saved", "info");
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    });
  };

  const handleChange = (e) => {
    e.persist();
    if (
      [
        "permanent_addr1",
        "permanent_addr2",
        "permanent_addr3",
        "permanent_city",
        "permanent_state",
        "permanent_country",
      ].includes(e.target.name) &&
      lead.current_addr_same &&
      lead.current_addr_same === "Y"
    ) {
      if (e.target.name.includes("addr1")) {
        setLead((old) => ({
          ...old,
          [e.target.name]: e.target.value,
          current_addr1: e.target.value,
        }));
      } else if (e.target.name.includes("addr2")) {
        setLead((old) => ({
          ...old,
          [e.target.name]: e.target.value,
          current_addr2: e.target.value,
        }));
      } else if (e.target.name.includes("addr3")) {
        setLead((old) => ({
          ...old,
          [e.target.name]: e.target.value,
          current_addr3: e.target.value,
        }));
      } else if (e.target.name.includes("city")) {
        setLead((old) => ({
          ...old,
          [e.target.name]: e.target.value,
          current_city: e.target.value,
        }));
      } else if (e.target.name.includes("state")) {
        setLead((old) => ({
          ...old,
          [e.target.name]: e.target.value,
          current_state: e.target.value,
        }));
      } else if (e.target.name.includes("country")) {
        setLead((old) => ({
          ...old,
          [e.target.name]: e.target.value,
          current_country: e.target.value,
        }));
      }
    } else {
      setLead((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeKV = (k, v) => {
    setLead((old) => ({ ...old, [k]: v }));
  };
  const handleChangeObject = (diff) => {
    setLead((old) => ({ ...old, ...diff }));
  };
  const handleChangeNominee = (index, field, value) => {
    console.log(index, field, value);

    setLead((old) => ({
      ...old,
      nominees: old.nominees.map((nominee, i) => {
        if (i === index) {
          return { ...nominee, [field]: value };
        } else {
          return nominee;
        }
      }),
    }));
    setErrorList((e) => ({
      ...e,
      nominees: e.nominees.map((nominee, i) => {
        if (i === index) {
          return { ...nominee, [field]: !value };
        } else {
          return nominee;
        }
      }),
    }));
  };

  const viewPennyDropTransactions = () => {
    if (lead && lead.lead_id) {
      setShowPennydropTransactions(true);
      setTxnLoading(true);
      LeadService.getPennydropTransactions(lead.lead_id)
        .then((data) => {
          if (data.transactions) {
            setTransactions(data.transactions);
          }
        })
        .catch((error) => {
          console.error(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => setTxnLoading(false));
    }
  };

  const refetchPanName = () => {
    if (lead && lead.lead_id) {
      showProgressDialog("Refetching PAN name");
      LeadService.refetchPANName(lead.lead_id)
        .then((d) => {
          if (d.success) {
            fetchData();
            showSnackBar(d.message, "success");
          } else if (d.error) {
            showSnackBar(d.error, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          showConfirmDialog1D(
            "Something went wrong. Please try again.",
            undefined,
            () => {
              closeConfirmDialog1D();
            }
          );
        })
        .finally(() => closeProgressDialog());
    }
  };

  const Location = () => {
    let location = undefined;
    if (proofs.has("ipv") && proofs.get("ipv").location) {
      location = proofs.get("ipv").location;
    } else if (proofs.has("photo") && proofs.get("photo").location) {
      location = proofs.get("photo").location;
    }
    if (!location) {
      return null;
    }

    return (
      <Grid item xs={12} lg={4} md={4}>
        <div className={classes.marginLeft}>
          <div
            style={{ display: "flex", alignItems: "center", paddingBottom: 10 }}
          >
            <Typography variant="h5" className={classes.title1}>
              Location
            </Typography>
            <div className={classes.grow} />
          </div>
          <img
            width="320"
            height="240"
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.address}&zoom=14&size=320x240&maptype=roadmap&markers=color:red|label:Lead|${location.latitude},${location.longitude}&key=${Config.GOOGLE_MAP_API_KEY}`}
            alt="Location"
          />
          <Typography component="div" variant="caption">
            {location.address}
          </Typography>
        </div>
        <br />
      </Grid>
    );
  };

  return (
    <Accordion
      square
      expanded={expandedDetails}
      onChange={() => setExpandedDetails((prev) => !prev)}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CardHeader
          title="Lead Information"
          titleTypographyProps={{ variant: "h4" }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ marginRight: 16, marginLeft: 16 }}>
          <Grid container justify="center" spacing={2} alignItems="center">
            {lead.client_code && (
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Client Code
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    {lead.client_code}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} lg={12}>
                  <Typography variant="h5" className={classes.title1}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    PAN
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Name"
                      margin={fieldMargin}
                      name="pan_name"
                      InputProps={{
                        readOnly: disabled,
                        endAdornment: (
                          <React.Fragment>
                            {lead.pan_name_refetch_left > 0 &&
                              [
                                STATUS.COMPLETED,
                                STATUS.ESIGN_PENDING,
                                STATUS.REJECTED,
                              ].includes(lead.status) ? (
                              <BootstrapTooltip arrow title="Re-fetch PAN Name">
                                <IconButton
                                  aria-label="close"
                                  onClick={refetchPanName}
                                >
                                  <Icon>refresh</Icon>
                                </IconButton>
                              </BootstrapTooltip>
                            ) : null}
                            {!editMode &&
                              !mobileView &&
                              segments &&
                              segments.pan_name &&
                              segments.pan_name.document_codes.some(
                                (d) =>
                                  lead &&
                                  lead.documents &&
                                  lead.documents.findIndex(
                                    (dd) => dd.document_code === d
                                  ) !== -1
                              ) ? (
                              <BootstrapTooltip arrow title="Compare Proofs">
                                <IconButton
                                  size="small"
                                  onClick={() => showCompare("pan_name")}
                                >
                                  <CompareIcon />
                                </IconButton>
                              </BootstrapTooltip>
                            ) : undefined}
                          </React.Fragment>
                        ),
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("pan_name")
                      }
                      id="outlined-adornment-pan_name"
                      value={lead.pan_name || (editMode ? "" : "   ")}
                      error={errorList.pan_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={12} lg={3} />
                </Hidden>
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Entered
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="First Name"
                      margin={fieldMargin}
                      name="first_name"
                      InputProps={{
                        readOnly: disabled,
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("first_name")
                      }
                      id="outlined-adornment-first_name"
                      value={lead.first_name || (editMode ? "" : "   ")}
                      error={errorList.first_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Middle Name"
                      margin={fieldMargin}
                      name="middle_name"
                      InputProps={{
                        readOnly: disabled,
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("middle_name")
                      }
                      id="outlined-adornment-middle_name"
                      value={lead.middle_name || (editMode ? "" : "   ")}
                      error={errorList.middle_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Last Name"
                      margin={fieldMargin}
                      name="last_name"
                      InputProps={{
                        readOnly: disabled,
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("last_name")
                      }
                      id="outlined-adornment-last_name"
                      value={lead.last_name || (editMode ? "" : "   ")}
                      error={errorList.last_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Aadhaar
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Name"
                      margin={fieldMargin}
                      name="aadhaar_name"
                      InputProps={{
                        readOnly: disabled,
                        endAdornment:
                          !editMode &&
                            !mobileView &&
                            lead.aadhaar_fetched === 1 &&
                            segments &&
                            segments.aadhaar_name &&
                            segments.aadhaar_name.document_codes.some(
                              (d) =>
                                lead &&
                                lead.documents &&
                                lead.documents.findIndex(
                                  (dd) => dd.document_code === d
                                ) !== -1
                            ) ? (
                            <BootstrapTooltip arrow title="Compare Proofs">
                              <IconButton
                                size="small"
                                onClick={() => showCompare("aadhaar_name")}
                              >
                                <CompareIcon />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : undefined,
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("aadhaar_name")
                      }
                      id="outlined-adornment-aadhaar_name"
                      value={lead.aadhaar_name || (editMode ? "" : "   ")}
                      error={errorList.aadhaar_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={12} lg={3} />
                </Hidden>
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    KRA
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Name"
                      margin={fieldMargin}
                      name="kra_name"
                      InputProps={{
                        readOnly: disabled && false,
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("kra_name")
                      }
                      id="outlined-adornment-kra_name"
                      value={lead.kra_name || (editMode ? "" : "   ")}
                      error={errorList.kra_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={12} lg={3} />
                </Hidden>
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Bank Account holder name
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Name"
                      margin={fieldMargin}
                      name="account_holder_name"
                      InputProps={{
                        readOnly: disabled && false,
                        endAdornment:
                          !editMode &&
                            !mobileView &&
                            lead.penny_drop === 0 &&
                            segments &&
                            segments.account_holder_name &&
                            segments.account_holder_name.document_codes.some(
                              (d) =>
                                lead &&
                                lead.documents &&
                                lead.documents.findIndex(
                                  (dd) => dd.document_code === d
                                ) !== -1
                            ) ? (
                            <BootstrapTooltip arrow title="Compare Proofs">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  showCompare("account_holder_name")
                                }
                              >
                                <CompareIcon />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : undefined,
                      }}
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes(
                          "account_holder_name"
                        )
                      }
                      id="outlined-adornment-account_holder_name"
                      value={
                        lead.account_holder_name || (editMode ? "" : "   ")
                      }
                      error={errorList.account_holder_name}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={12} lg={3} />
                </Hidden>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Details verified
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9} md={9}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.aadhaar_mobile_linked === 1}
                          name="aadhaar_mobile_linked"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="Aadhaar Mobile linked"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.aadhaar_fetched === 1}
                          name="aadhaar_fetched"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="Aadhaar Fetched"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.pan_fetched === 1}
                          name="pan_fetched"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="PAN Fetched"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.nsdl_pan_fetched === 1}
                          name="nsdl_pan_fetched"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="NSDL Name Fetched"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.kra_registered === 1}
                          name="kra_registered"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="KRA Registered"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.penny_drop === 1}
                          name="penny_drop"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="Penny Drop Success"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.reverse_penny_drop === 1}
                          name="reverse_penny_drop"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="Reverse Penny Drop Success"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.bank_fetched === 1}
                          name="bank_fetched"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="Bank Details Fetched"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lead.micr_fetched === 1}
                          name="micr_fetched"
                          color="default"
                          disabled={editMode}
                        />
                      }
                      label="MICR Fetched"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems={editMode ? "flex-start" : "center"}
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Proof details
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="Aadhaar number"
                      margin={fieldMargin}
                      name="aadhaar"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            XXXX-XXXX-
                          </InputAdornment>
                        ),
                        readOnly: disabled,
                        endAdornment:
                          !editMode &&
                            !mobileView &&
                            segments &&
                            segments.aadhaar &&
                            segments.aadhaar.document_codes.some(
                              (d) =>
                                lead &&
                                lead.documents &&
                                lead.documents.findIndex(
                                  (dd) => dd.document_code === d
                                ) !== -1
                            ) ? (
                            <BootstrapTooltip arrow title="Compare Proofs">
                              <IconButton
                                size="small"
                                onClick={() => showCompare("aadhaar")}
                              >
                                <CompareIcon />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : undefined,
                        inputProps: {
                          maxLength: 4,
                        },
                      }}
                      type="text"
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("aadhaar")
                      }
                      id="outlined-adornment-aadhaar"
                      value={
                        lead.aadhaar
                          ? lead.aadhaar.replaceAll("X", "")
                          : editMode
                            ? ""
                            : "   "
                      }
                      error={errorList.aadhaar}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <FormControl variant={fieldVariant} fullWidth>
                    <TextField
                      label="PAN Number"
                      margin={fieldMargin}
                      name="pan"
                      InputProps={{
                        readOnly: disabled,
                        endAdornment:
                          !editMode &&
                            !mobileView &&
                            segments &&
                            segments.pan &&
                            segments.pan.document_codes.some(
                              (d) =>
                                lead &&
                                lead.documents &&
                                lead.documents.findIndex(
                                  (dd) => dd.document_code === d
                                ) !== -1
                            ) ? (
                            <BootstrapTooltip arrow title="Compare Proofs">
                              <IconButton
                                size="small"
                                onClick={() => showCompare("pan")}
                              >
                                <CompareIcon />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : undefined,
                        inputProps: {
                          maxLength: 10,
                        },
                      }}
                      type="text"
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("pan")
                      }
                      id="outlined-adornment-pan"
                      value={lead.pan || (editMode ? "" : "   ")}
                      error={errorList.pan}
                      onChange={handleChange}
                      aria-describedby="outlined-weight-helper-text"
                      variant={fieldVariant}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <FormControl variant={fieldVariant} fullWidth>
                    {editMode &&
                      lead.editable_fields &&
                      lead.editable_fields.lead.includes("dob") ? (
                      <SingleDate
                        margin="dense"
                        required
                        disabled={disabled}
                        label="Date of Birth"
                        date={
                          lead.dob
                            ? moment(lead.dob, format_without_time)
                            : undefined
                        }
                        error={errorList.dob}
                        setDate={(date) =>
                          handleChangeKV(
                            "dob",
                            date ? date.format(format_without_time) : null
                          )
                        }
                      />
                    ) : (
                      <TextField
                        label="Date of Birth"
                        margin={fieldMargin}
                        name="dob"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode}
                        id="outlined-adornment-dob"
                        value={
                          lead.dob
                            ? moment(lead.dob, format_without_time).format(
                              format_display
                            )
                            : "   "
                        }
                        error={errorList.dob}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                direction="row"
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Personal details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Phone number"
                        margin={fieldMargin}
                        name="mobile"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        type="number"
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("mobile")
                        }
                        id="outlined-adornment-mobile"
                        value={lead.mobile || (editMode ? "" : "   ")}
                        error={errorList.mobile}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Email ID"
                        margin={fieldMargin}
                        name="email"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("email")
                        }
                        id="outlined-adornment-email"
                        value={lead.email || (editMode ? "" : "   ")}
                        error={errorList.email}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    {editMode ? (
                      <ItemSelection
                        required
                        size={fieldSize}
                        label="Education qualification"
                        value={
                          lead.education
                            ? educationOptions.find(
                              (i) => i.value === lead.education.value
                            )
                            : null
                        }
                        optionLabel="label"
                        error={errorList.education}
                        options={educationOptions}
                        style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                        formClass={classes.formAutoClass}
                        selected={(value) => {
                          handleChangeKV("education", value);
                          setErrorList((e) => ({ ...e, education: !value }));
                        }}
                      />
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Education qualification"
                          margin={fieldMargin}
                          name="education"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          id="outlined-adornment-education"
                          value={lead.education ? lead.education.label : "   "}
                          error={errorList.education}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    {editMode ? (
                      <ItemSelection
                        required
                        size={fieldSize}
                        label="Occupation"
                        value={
                          lead.occupation
                            ? occupationOptions.find(
                              (i) => i.value === lead.occupation.value
                            )
                            : null
                        }
                        optionLabel="label"
                        error={errorList.occupation}
                        options={occupationOptions}
                        style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                        formClass={classes.formAutoClass}
                        selected={(value) => {
                          handleChangeKV("occupation", value);
                          setErrorList((e) => ({ ...e, occupation: !value }));
                        }}
                      />
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Occupation"
                          margin={fieldMargin}
                          name="occupation"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          id="outlined-adornment-occupation"
                          value={
                            lead.occupation ? lead.occupation.label : "   "
                          }
                          error={errorList.occupation}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    {editMode ? (
                      <ItemSelection
                        required
                        size={fieldSize}
                        label="Income range"
                        value={
                          lead.annual_income
                            ? annualIncomeOptions.find(
                              (i) => i.value === lead.annual_income.value
                            )
                            : null
                        }
                        optionLabel="label"
                        error={errorList.annual_income}
                        options={annualIncomeOptions}
                        style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                        formClass={classes.formAutoClass}
                        selected={(value) => {
                          handleChangeKV("annual_income", value);
                          setErrorList((e) => ({
                            ...e,
                            annual_income: !value,
                          }));
                        }}
                      />
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Income range"
                          margin={fieldMargin}
                          name="annual_income"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          id="outlined-adornment-annual_income"
                          value={
                            lead.annual_income
                              ? lead.annual_income.label
                              : "   "
                          }
                          error={errorList.annual_income}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset" disabled={editMode}>
                      <FormLabel component="legend">
                        Gender
                        {!editMode &&
                          !mobileView &&
                          lead.aadhaar_fetched === 0 &&
                          segments &&
                          segments.gender &&
                          segments.gender.document_codes.some(
                            (d) =>
                              lead &&
                              lead.documents &&
                              lead.documents.findIndex(
                                (dd) => dd.document_code === d
                              ) !== -1
                          ) ? (
                          <BootstrapTooltip arrow title="Compare Proofs">
                            <IconButton
                              size="small"
                              onClick={() => showCompare("gender")}
                            >
                              <CompareIcon />
                            </IconButton>
                          </BootstrapTooltip>
                        ) : undefined}
                      </FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender"
                        value={lead.gender}
                        onChange={(e) => {
                          if (!disabled)
                            handleChangeKV("gender", e.target.value);
                        }}
                      >
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("gender")
                          }
                          value="F"
                          control={<Radio color="default" />}
                          label={GENDER.F}
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("gender")
                          }
                          value="M"
                          control={<Radio color="default" />}
                          label={GENDER.M}
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("gender")
                          }
                          value="O"
                          control={<Radio color="default" />}
                          label={GENDER.O}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Marital status</FormLabel>
                      <RadioGroup
                        aria-label="marital_status"
                        name="marital_status"
                        value={lead.marital_status}
                        onChange={(e) => {
                          if (!disabled)
                            handleChangeKV("marital_status", e.target.value);
                        }}
                      >
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "marital_status"
                            )
                          }
                          value="M"
                          control={<Radio color="default" />}
                          label="Married"
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "marital_status"
                            )
                          }
                          value="S"
                          control={<Radio color="default" />}
                          label="Single"
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "marital_status"
                            )
                          }
                          value="O"
                          control={<Radio color="default" />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset" disabled={editMode}>
                      <FormLabel component="legend">Nationality</FormLabel>
                      <RadioGroup
                        aria-label="nationality"
                        name="nationality"
                        value={lead.nationality}
                        onChange={(e) => {
                          if (!disabled)
                            handleChangeKV("nationality", e.target.value);
                        }}
                      >
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("nationality")
                          }
                          value={1}
                          control={<Radio color="default" />}
                          label="Indian"
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("nationality")
                          }
                          value={0}
                          control={<Radio color="default" />}
                          label="Others"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Mother’s name"
                        margin={fieldMargin}
                        name="mother_name"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("mother_name")
                        }
                        id="outlined-adornment-mother_name"
                        value={lead.mother_name || (editMode ? "" : "   ")}
                        error={errorList.mother_name}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Father’s name"
                        margin={fieldMargin}
                        name="father_name"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("father_name")
                        }
                        id="outlined-adornment-father_name"
                        value={lead.father_name || (editMode ? "" : "   ")}
                        error={errorList.father_name}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    {editMode ? (
                      <ItemSelectionAsync
                        isCity
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("birth_place")
                        }
                        required
                        size={fieldSize}
                        label="Place of birth"
                        value={
                          lead.birth_place
                            ? {
                              city: lead.birth_place,
                            }
                            : null
                        }
                        optionLabel="city"
                        labelTemplate={(option) => option.city}
                        error={errorList.birth_place}
                        style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                        formClass={classes.formAutoClass}
                        selected={(value) => {
                          handleChangeObject({
                            birth_place: value.city,
                          });
                          setErrorList((e) => ({
                            ...e,
                            birth_place: !value.birth_place,
                          }));
                        }}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon className={classes.icon} />
                              </Grid>
                              <Grid item xs>
                                <span style={{ fontWeight: 400 }}>
                                  {option.city}
                                </span>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`${option.state}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Place of birth"
                          margin={fieldMargin}
                          name="birth_place"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("birth_place")
                          }
                          id="outlined-adornment-birth_place"
                          value={lead.birth_place || (editMode ? "" : "   ")}
                          error={errorList.birth_place}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Typography
                      variant="h6"
                      className={classes.title1}
                      component="span"
                      style={{ marginRight: 16 }}
                    >
                      Permanent Address
                    </Typography>
                    {!editMode &&
                      !mobileView &&
                      segments &&
                      segments.permanent_address &&
                      segments.permanent_address.document_codes.some(
                        (d) =>
                          lead &&
                          lead.documents &&
                          lead.documents.findIndex(
                            (dd) => dd.document_code === d
                          ) !== -1
                      ) ? (
                      <BootstrapTooltip arrow title="Compare Proofs">
                        <IconButton
                          size="small"
                          onClick={() => showCompare("permanent_address")}
                        >
                          <CompareIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    ) : undefined}
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 1"
                        margin={fieldMargin}
                        name="permanent_addr1"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("permanent_addr1")
                        }
                        id="outlined-adornment-permanent_addr1"
                        value={lead.permanent_addr1 || (editMode ? "" : "   ")}
                        error={errorList.permanent_addr1}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 2"
                        margin={fieldMargin}
                        name="permanent_addr2"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("permanent_addr2")
                        }
                        id="outlined-adornment-permanent_addr2"
                        value={lead.permanent_addr2 || (editMode ? "" : "   ")}
                        error={errorList.permanent_addr2}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 3"
                        margin={fieldMargin}
                        name="permanent_addr3"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("permanent_addr3")
                        }
                        id="outlined-adornment-permanent_addr3"
                        value={lead.permanent_addr3 || (editMode ? "" : "   ")}
                        error={errorList.permanent_addr3}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="City"
                        margin={fieldMargin}
                        name="permanent_city"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("permanent_city")
                        }
                        id="outlined-adornment-permanent_city"
                        value={lead.permanent_city || (editMode ? "" : "   ")}
                        error={errorList.permanent_city}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="State"
                        margin={fieldMargin}
                        name="permanent_state"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("permanent_state")
                        }
                        id="outlined-adornment-permanent_state"
                        value={lead.permanent_state || (editMode ? "" : "   ")}
                        error={errorList.permanent_state}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Country"
                        margin={fieldMargin}
                        name="permanent_country"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes(
                          //  "permanent_country"
                          //)
                        }
                        id="outlined-adornment-permanent_country"
                        value={
                          lead.permanent_country || (editMode ? "" : "   ")
                        }
                        error={errorList.permanent_country}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    {editMode ? (
                      lead.nationality === 1 ? (
                        <ItemSelectionAsync
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "permanent_pincode"
                            )
                          }
                          required
                          size={fieldSize}
                          label="Pincode"
                          value={
                            lead.permanent_pincode
                              ? {
                                pincode: parseInt(lead.permanent_pincode),
                                state: lead.permanent_state,
                                city: lead.permanent_city,
                              }
                              : null
                          }
                          optionLabel="pincode"
                          labelTemplate={(option) => option.pincode}
                          error={errorList.permanent_pincode}
                          style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                          formClass={classes.formAutoClass}
                          selected={(value) => {
                            if (
                              lead.current_addr_same &&
                              lead.current_addr_same === "Y"
                            ) {
                              handleChangeObject({
                                permanent_pincode: value.pincode,
                                permanent_city: value.city,
                                permanent_state: value.state,
                                current_pincode: value.pincode,
                                current_city: value.city,
                                current_state: value.state,
                              });
                              setErrorList((e) => ({
                                ...e,
                                permanent_pincode: !value,
                                permanent_city: !value.city,
                                permanent_state: !value.state,
                                current_pincode: !value,
                                current_city: !value.city,
                                current_state: !value.state,
                              }));
                            } else {
                              handleChangeObject({
                                permanent_pincode: value.pincode,
                                permanent_city: value.city,
                                permanent_state: value.state,
                              });
                              setErrorList((e) => ({
                                ...e,
                                permanent_pincode: !value,
                                permanent_city: !value.city,
                                permanent_state: !value.state,
                              }));
                            }
                          }}
                          renderOption={(option) => {
                            return (
                              <Grid container alignItems="center">
                                <Grid item>
                                  <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                  <span style={{ fontWeight: 400 }}>
                                    {option.pincode}
                                  </span>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`${option.city}, ${option.state}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }}
                        />
                      ) : (
                        <FormControl variant={fieldVariant} fullWidth>
                          <TextField
                            label="Pincode"
                            margin={fieldMargin}
                            name="permanent_pincode"
                            InputProps={{
                              readOnly: disabled,
                            }}
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes(
                                "permanent_pincode"
                              )
                            }
                            id="outlined-adornment-permanent_pincode"
                            value={
                              lead.permanent_pincode || (editMode ? "" : "   ")
                            }
                            error={errorList.permanent_pincode}
                            onChange={(e) => {
                              e.persist();
                              if (
                                lead.current_addr_same &&
                                lead.current_addr_same === "Y"
                              ) {
                                handleChangeObject({
                                  permanent_pincode: e.target.value,
                                  current_pincode: e.target.value,
                                });
                                setErrorList((e) => ({
                                  ...e,
                                  permanent_pincode: !e.target.value,
                                  current_pincode: !e.target.value,
                                }));
                              } else {
                                handleChangeObject({
                                  permanent_pincode: e.target.value,
                                });
                                setErrorList((e) => ({
                                  ...e,
                                  permanent_pincode: !e.target.value,
                                }));
                              }
                            }}
                            aria-describedby="outlined-weight-helper-text"
                            variant={fieldVariant}
                          />
                        </FormControl>
                      )
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Pincode"
                          margin={fieldMargin}
                          name="permanent_pincode"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          id="outlined-adornment-permanent_pincode"
                          value={
                            lead.permanent_pincode || (editMode ? "" : "   ")
                          }
                          error={errorList.permanent_pincode}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    md={12}
                    spacing={1}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        className={classes.title1}
                        style={{ marginRight: 16 }}
                      >
                        Current Address
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        disabled={
                          lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "current_addr_same"
                            )
                            ? true
                            : disabled
                        }
                        control={
                          <Checkbox
                            checked={
                              lead.current_addr_same &&
                              lead.current_addr_same === "Y"
                            }
                            name="current_addr_same"
                            color="primary"
                          />
                        }
                        label="Same as Permanent"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChangeObject({
                              current_addr_same: "Y",
                              current_addr1: lead.permanent_addr1,
                              current_addr2: lead.permanent_addr2,
                              current_addr3: lead.permanent_addr3,
                              current_city: lead.permanent_city,
                              current_state: lead.permanent_state,
                              current_country: lead.permanent_country,
                              current_pincode: lead.permanent_pincode,
                            });
                          } else {
                            handleChangeKV("current_addr_same", "N");
                            if (
                              !lead.editable_fields.lead.includes(
                                "current_addr1"
                              )
                            ) {
                              handleChangeObject({
                                current_addr1: originalLead.current_addr1,
                                current_addr2: originalLead.current_addr2,
                                current_addr3: originalLead.current_addr3,
                                current_city: originalLead.current_city,
                                current_state: originalLead.current_state,
                                current_country: originalLead.current_country,
                                current_pincode: originalLead.current_pincode,
                              });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {!editMode &&
                        !mobileView &&
                        segments &&
                        segments.current_address &&
                        segments.current_address.document_codes.some(
                          (d) =>
                            lead &&
                            lead.documents &&
                            lead.documents.findIndex(
                              (dd) => dd.document_code === d
                            ) !== -1
                        ) ? (
                        <BootstrapTooltip arrow title="Compare Proofs">
                          <IconButton
                            size="small"
                            onClick={() => showCompare("current_address")}
                          >
                            <CompareIcon />
                          </IconButton>
                        </BootstrapTooltip>
                      ) : undefined}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 1"
                        margin={fieldMargin}
                        name="current_addr1"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                            !lead.editable_fields.lead.includes("current_addr1")
                            ? true
                            : lead.current_addr_same &&
                            lead.current_addr_same === "Y"
                        }
                        value={lead.current_addr1 || (editMode ? "" : "   ")}
                        error={errorList.current_addr1}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 2"
                        margin={fieldMargin}
                        name="current_addr2"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                            !lead.editable_fields.lead.includes("current_addr2")
                            ? true
                            : lead.current_addr_same &&
                            lead.current_addr_same === "Y"
                        }
                        value={lead.current_addr2 || (editMode ? "" : "   ")}
                        error={errorList.current_addr2}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 3"
                        margin={fieldMargin}
                        name="current_addr3"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                            !lead.editable_fields.lead.includes("current_addr3")
                            ? true
                            : lead.current_addr_same &&
                            lead.current_addr_same === "Y"
                        }
                        value={lead.current_addr3 || (editMode ? "" : "   ")}
                        error={errorList.current_addr3}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="City"
                        margin={fieldMargin}
                        name="current_city"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode ||
                          (lead.current_addr_same &&
                            lead.current_addr_same === "Y")
                          //lead.editable_fields &&
                          //  !lead.editable_fields.lead.includes("current_city")
                          //  ? true
                          //  : lead.current_addr_same &&
                          //  lead.current_addr_same === "Y"
                        }
                        value={lead.current_city || (editMode ? "" : "   ")}
                        error={errorList.current_city}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="State"
                        margin={fieldMargin}
                        name="current_state"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode ||
                          (lead.current_addr_same &&
                            lead.current_addr_same === "Y")
                          //lead.editable_fields &&
                          //  !lead.editable_fields.lead.includes("current_state")
                          //  ? true
                          //  : lead.current_addr_same &&
                          //  lead.current_addr_same === "Y"
                        }
                        value={lead.current_state || (editMode ? "" : "   ")}
                        error={errorList.current_state}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Country"
                        margin={fieldMargin}
                        name="current_country"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode ||
                          (lead.current_addr_same &&
                            lead.current_addr_same === "Y")
                          //lead.editable_fields &&
                          //  !lead.editable_fields.lead.includes("current_country")
                          //  ? true
                          //  : lead.current_addr_same &&
                          //  lead.current_addr_same === "Y"
                        }
                        value={lead.current_country || (editMode ? "" : "   ")}
                        error={errorList.current_country}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    {editMode ? (
                      lead.nationality === 1 ? (
                        <ItemSelectionAsync
                          disabled={
                            lead.editable_fields &&
                              !lead.editable_fields.lead.includes(
                                "current_pincode"
                              )
                              ? true
                              : lead.current_addr_same &&
                              lead.current_addr_same === "Y"
                          }
                          required
                          size={fieldSize}
                          readOnly={disabled}
                          label="Pincode"
                          value={
                            lead.current_pincode
                              ? {
                                pincode: parseInt(lead.current_pincode),
                                state: lead.current_state,
                                city: lead.current_city,
                              }
                              : null
                          }
                          optionLabel="pincode"
                          labelTemplate={(option) => option.pincode}
                          error={errorList.current_pincode}
                          style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                          formClass={classes.formAutoClass}
                          selected={(value) => {
                            handleChangeObject({
                              current_pincode: value.pincode,
                              current_city: value.city,
                              current_state: value.state,
                            });
                            setErrorList((e) => ({
                              ...e,
                              current_pincode: !value,
                              current_city: !value.city,
                              current_state: !value.state,
                            }));
                          }}
                          renderOption={(option) => {
                            return (
                              <Grid container alignItems="center">
                                <Grid item>
                                  <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                  <span style={{ fontWeight: 400 }}>
                                    {option.pincode}
                                  </span>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`${option.city}, ${option.state}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          }}
                        />
                      ) : (
                        <FormControl variant={fieldVariant} fullWidth>
                          <TextField
                            label="Pincode"
                            margin={fieldMargin}
                            name="current_pincode"
                            InputProps={{
                              readOnly: disabled,
                            }}
                            disabled={
                              lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "current_pincode"
                                )
                                ? true
                                : lead.current_addr_same &&
                                lead.current_addr_same === "Y"
                            }
                            value={
                              lead.current_pincode || (editMode ? "" : "   ")
                            }
                            error={errorList.current_pincode}
                            onChange={handleChange}
                            aria-describedby="outlined-weight-helper-text"
                            variant={fieldVariant}
                          />
                        </FormControl>
                      )
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Pincode"
                          disabled={
                            lead.current_addr_same &&
                            lead.current_addr_same === "Y"
                          }
                          margin={fieldMargin}
                          name="current_pincode"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          value={
                            lead.current_pincode || (editMode ? "" : "   ")
                          }
                          error={errorList.current_pincode}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Grid item>
                      <Typography variant="h6" className={classes.title1}>
                        Trading pattern
                      </Typography>
                    </Grid>
                    <FormGroup row>
                      <FormControlLabel
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("algo_pattern")
                        }
                        control={
                          <Checkbox
                            checked={lead.algo_pattern}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Algo"
                        onChange={(e) => {
                          if (!disabled) {
                            handleChangeKV("algo_pattern", e.target.checked);
                          }
                        }}
                      />
                      <FormControlLabel
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes(
                            "intraday_pattern"
                          )
                        }
                        control={
                          <Checkbox
                            checked={lead.intraday_pattern}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Intraday"
                        onChange={(e) => {
                          if (!disabled) {
                            handleChangeKV(
                              "intraday_pattern",
                              e.target.checked
                            );
                          }
                        }}
                      />
                      <FormControlLabel
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes(
                            "positional_pattern"
                          )
                        }
                        control={
                          <Checkbox
                            checked={lead.positional_pattern}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Positional"
                        onChange={(e) => {
                          if (!disabled) {
                            handleChangeKV(
                              "positional_pattern",
                              e.target.checked
                            );
                          }
                        }}
                      />
                      <FormControlLabel
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes(
                            "investment_pattern"
                          )
                        }
                        control={
                          <Checkbox
                            checked={lead.investment_pattern}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Investment"
                        onChange={(e) => {
                          if (!disabled) {
                            handleChangeKV(
                              "investment_pattern",
                              e.target.checked
                            );
                          }
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    {editMode ? (
                      <FormControl component="fieldset" disabled={editMode}>
                        <FormLabel component="legend">Depository</FormLabel>
                        <RadioGroup
                          aria-label="depository"
                          name="depository"
                          value={lead.depository}
                          onChange={(e) => {
                            if (!disabled)
                              handleChangeKV("depository", e.target.value);
                          }}
                        >
                          {DEPOSITORY.map((value) => (
                            <FormControlLabel
                              key={value}
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "depository"
                                )
                              }
                              value={value}
                              control={<Radio color="default" />}
                              label={value}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Depository"
                          margin={fieldMargin}
                          name="depository"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          value={lead.depository || (editMode ? "" : "   ")}
                          error={errorList.depository}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    {editMode ? (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Running Account Settlement</FormLabel>
                        <RadioGroup
                          aria-label="settlement_type"
                          name="settlement_type"
                          value={lead.settlement_type}
                          onChange={(e) => {
                            if (
                              !disabled &&
                              lead.editable_fields &&
                              lead.editable_fields.lead.includes(
                                "settlement_type"
                              )
                            ) {
                              handleChangeKV(
                                "settlement_type",
                                e.target.value
                              );
                            }
                          }}
                        >
                          {SETTLEMENT_OPTIONS.map(option => (
                            <FormControlLabel
                              key={option.value}
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "settlement_type"
                                )
                              }
                              value={option.value}
                              control={<Radio color="default" />}
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Running Account Settlement"
                          margin={fieldMargin}
                          name="settlement_type"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          value={lead.settlement_type}
                          error={errorList.settlement_type}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    {editMode ? (
                      <ItemSelection
                        required
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes(
                            "trading_experience"
                          )
                        }
                        size={fieldSize}
                        label="Trading experience"
                        value={
                          lead.trading_experience &&
                            tradingExperienceOptions.length > 0
                            ? tradingExperienceOptions.find(
                              (i) => i.value === lead.trading_experience.value
                            )
                            : null
                        }
                        optionLabel="label"
                        error={errorList.trading_experience}
                        options={tradingExperienceOptions}
                        style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                        formClass={classes.formAutoClass}
                        selected={(value) => {
                          handleChangeKV("trading_experience", value);
                          setErrorList((e) => ({
                            ...e,
                            trading_experience: !value,
                          }));
                        }}
                      />
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Trading experience"
                          margin={fieldMargin}
                          name="trading_experience"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          value={
                            lead.trading_experience
                              ? lead.trading_experience.label
                              : "   "
                          }
                          error={errorList.trading_experience}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">PEP</FormLabel>
                      <RadioGroup
                        row
                        aria-label="politically_exposed"
                        name="politically_exposed"
                        value={lead.politically_exposed}
                        onChange={(e) => {
                          if (
                            !disabled &&
                            lead.editable_fields &&
                            lead.editable_fields.lead.includes(
                              "politically_exposed"
                            )
                          ) {
                            handleChangeKV(
                              "politically_exposed",
                              e.target.value
                            );
                          }
                        }}
                      >
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "politically_exposed"
                            )
                          }
                          value="Yes"
                          control={<Radio color="default" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "politically_exposed"
                            )
                          }
                          value="No"
                          control={<Radio color="default" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {/* <Hidden xsDown>
                    <Grid item xs={12} lg={4} md={4} />
                  </Hidden> */}
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Registered with any other broker
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="broker_registered"
                        name="broker_registered"
                        value={lead.broker_registered}
                        onChange={(e) => {
                          if (
                            !disabled &&
                            lead.editable_fields &&
                            lead.editable_fields.lead.includes(
                              "broker_registered"
                            )
                          ) {
                            if (e.target.value === "true") {
                              handleChangeKV("broker_registered", true);
                            } else {
                              handleChangeObject({
                                broker_registered: false,
                                broker_name: "",
                              });
                            }
                          }
                        }}
                      >
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "broker_registered"
                            )
                          }
                          value={true}
                          control={<Radio color="default" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes(
                              "broker_registered"
                            )
                          }
                          value={false}
                          control={<Radio color="default" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {lead.broker_registered && (
                    <Grid item xs={12} lg={3} md={3}>
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Name of the broker"
                          margin={fieldMargin}
                          name="broker_name"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          disabled={
                            lead.editable_fields &&
                            !lead.editable_fields.lead.includes("broker_name")
                          }
                          id="outlined-adornment-broker_name"
                          value={lead.broker_name || (editMode ? "" : "   ")}
                          error={errorList.broker_name}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                direction="row"
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Nominees
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={12} md={12}>
                    <FormControl
                      component="fieldset"
                      disabled={
                        lead.editable_fields &&
                        !lead.editable_fields.lead.includes("nominee")
                      }
                    >
                      <FormLabel component="legend">Nominee Opted?</FormLabel>
                      <RadioGroup
                        row
                        aria-label="dependent"
                        name="nominee"
                        value={lead.nominee}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="default" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="default" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    {lead.nominees.map((nominee, index) => (
                      <Grid
                        key={index}
                        container
                        direction="row"
                        justify="flex-start"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={12} lg={12} md={12}>
                          <Typography
                            variant="h5"
                            className={classes.title1}
                            component="span"
                            style={{ marginRight: 16 }}
                          >
                            Nominee {index + 1}
                          </Typography>
                          {!editMode &&
                            !mobileView &&
                            segments &&
                            segments[`nominee${index}`] &&
                            segments[`nominee${index}`].document_codes.some(
                              (d) =>
                                lead &&
                                lead.documents &&
                                lead.documents.findIndex(
                                  (dd) => dd.document_code === d
                                ) !== -1
                            ) ? (
                            <BootstrapTooltip arrow title="Compare Proofs">
                              <IconButton
                                size="small"
                                onClick={() => showCompare(`nominee${index}`)}
                              >
                                <CompareIcon />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : undefined}
                        </Grid>
                        <Grid item xs={12} lg={3} md={3}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Name"
                              margin={fieldMargin}
                              name="name"
                              InputProps={{
                                readOnly: disabled,
                              }}
                              type="text"
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "nominee_name"
                                )
                              }
                              value={nominee.name || (editMode ? "" : "   ")}
                              onChange={(e) =>
                                handleChangeNominee(
                                  index,
                                  "name",
                                  e.target.value
                                )
                              }
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2} md={2}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Phone number"
                              margin={fieldMargin}
                              name="mobile"
                              InputProps={{
                                readOnly: disabled,
                              }}
                              type="number"
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "nominee_mobile"
                                )
                              }
                              value={nominee.mobile || (editMode ? "" : "   ")}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={3} md={3}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Email ID"
                              margin={fieldMargin}
                              name="email"
                              InputProps={{
                                readOnly: disabled,
                              }}
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "nominee_email"
                                )
                              }
                              value={nominee.email || (editMode ? "" : "   ")}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2} md={2} xl={3}>
                          {editMode ? (
                            <ItemSelection
                              required
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "nominee_relationship"
                                )
                              }
                              size={fieldSize}
                              label="Relationship"
                              value={
                                nominee.relationship &&
                                  relationshipOptions.length > 0
                                  ? relationshipOptions.find(
                                    (i) => i.value === nominee.relationship
                                  )
                                  : null
                              }
                              error={
                                errorList &&
                                  errorList.nominees &&
                                  errorList.nominees[index]
                                  ? errorList.nominees[index].relationship
                                  : false
                              }
                              optionLabel="label"
                              options={relationshipOptions}
                              style={{
                                zIndex: 500,
                                marginTop: 4,
                                marginLeft: 0,
                              }}
                              formClass={classes.formAutoClass}
                              selected={(value) => {
                                handleChangeNominee(
                                  index,
                                  "relationship",
                                  value.value
                                );
                              }}
                            />
                          ) : (
                            <FormControl variant={fieldVariant} fullWidth>
                              <TextField
                                label="Relationship"
                                margin={fieldMargin}
                                name="relationship"
                                InputProps={{
                                  readOnly: disabled,
                                }}
                                value={
                                  nominee.relationship ||
                                  (editMode ? "" : "   ")
                                }
                                onChange={handleChange}
                                aria-describedby="outlined-weight-helper-text"
                                variant={fieldVariant}
                              />
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={2} md={2} xl={1}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Share"
                              margin={fieldMargin}
                              name="share"
                              InputProps={{
                                readOnly: disabled,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "nominee_share"
                                )
                              }
                              value={nominee.share || (editMode ? "" : "   ")}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          lg={12}
                          md={12}
                          spacing={1}
                          justify="flex-start"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                              className={classes.title1}
                              style={{ marginRight: 16 }}
                            >
                              Address
                            </Typography>
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              disabled={
                                lead.editable_fields &&
                                !lead.editable_fields.lead.includes(
                                  "nominee_addr_same"
                                )
                              }
                              control={
                                <Checkbox
                                  checked={nominee.nominee_addr_same}
                                  name="nominee_addr_same"
                                  color="primary"
                                />
                              }
                              label="Same as Permanent Address"
                            />
                          </Grid>
                        </Grid>
                        {!nominee.nominee_addr_same && (
                          <Grid
                            item
                            container
                            xs={12}
                            lg={12}
                            md={12}
                            spacing={1}
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs={12} lg={4} md={4}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Address Line 1"
                                  margin={fieldMargin}
                                  name="addr1"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes("addr1")
                                  }
                                  value={
                                    nominee.addr1 || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Address Line 2"
                                  margin={fieldMargin}
                                  name="addr2"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes("addr2")
                                  }
                                  value={
                                    nominee.addr2 || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Address Line 3"
                                  margin={fieldMargin}
                                  name="addr3"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes("addr3")
                                  }
                                  value={
                                    nominee.addr3 || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={2} md={2}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="City"
                                  margin={fieldMargin}
                                  name="city"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  disabled={
                                    editMode
                                    //lead.editable_fields &&
                                    //!lead.editable_fields.lead.includes(
                                    //  "nominee_city"
                                    //)
                                  }
                                  value={
                                    nominee.city || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={2} md={2}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="State"
                                  margin={fieldMargin}
                                  name="state"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  disabled={
                                    editMode
                                    //lead.editable_fields &&
                                    //!lead.editable_fields.lead.includes(
                                    //  "nominee_state"
                                    //)
                                  }
                                  value={
                                    nominee.state || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={2} md={2}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Country"
                                  margin={fieldMargin}
                                  name="country"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  disabled={
                                    editMode
                                    //lead.editable_fields &&
                                    //!lead.editable_fields.lead.includes(
                                    //  "nominee_country"
                                    //)
                                  }
                                  value={
                                    nominee.country || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={2} md={2}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Pincode"
                                  margin={fieldMargin}
                                  name="pincode"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "nominee_pincode"
                                    )
                                  }
                                  value={
                                    nominee.pincode || (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12} lg={12} md={12}>
                          <FormControl
                            component="fieldset"
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes("major")
                            }
                          >
                            <FormLabel component="legend">
                              Is your nominee above 18 years?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label="dependent"
                              name="nominee"
                              value={nominee.major}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio color="default" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio color="default" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {!nominee.major &&
                            !editMode &&
                            !mobileView &&
                            segments &&
                            segments[`guardian${index}`] &&
                            segments[`guardian${index}`].document_codes.some(
                              (d) =>
                                lead &&
                                lead.documents &&
                                lead.documents.findIndex(
                                  (dd) => dd.document_code === d
                                ) !== -1
                            ) ? (
                            <BootstrapTooltip arrow title="Compare Proofs">
                              <IconButton
                                size="small"
                                onClick={() => showCompare(`guardian${index}`)}
                                style={{ marginLeft: 24, marginTop: 8 }}
                              >
                                <CompareIcon />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : undefined}
                        </Grid>
                        {!nominee.major && (
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            md={12}
                            container
                            direction="row"
                            justify="flex-start"
                            spacing={1}
                            alignItems="flex-start"
                          >
                            <Grid item xs={12} lg={3} md={3}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Date of Birth"
                                  margin={fieldMargin}
                                  name="dob"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "nominee_dob"
                                    )
                                  }
                                  value={
                                    nominee.dob
                                      ? moment(
                                        nominee.dob,
                                        format_without_time
                                      ).format(format_display)
                                      : "   "
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={3} md={3}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Guardian Name"
                                  margin={fieldMargin}
                                  name="guardian_name"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  type="text"
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "guardian_name"
                                    )
                                  }
                                  value={
                                    nominee.guardian_name ||
                                    (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={3} md={3}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Guardian Phone number"
                                  margin={fieldMargin}
                                  name="guardian_mobile"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  type="text"
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "guardian_mobile"
                                    )
                                  }
                                  value={
                                    nominee.guardian_mobile ||
                                    (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={3} md={3}>
                              <FormControl variant={fieldVariant} fullWidth>
                                <TextField
                                  label="Guardian Email ID"
                                  margin={fieldMargin}
                                  name="guardian_email"
                                  InputProps={{
                                    readOnly: disabled,
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "guardian_email"
                                    )
                                  }
                                  value={
                                    nominee.guardian_email ||
                                    (editMode ? "" : "   ")
                                  }
                                  onChange={handleChange}
                                  aria-describedby="outlined-weight-helper-text"
                                  variant={fieldVariant}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={3} md={3}>
                              {editMode ? (
                                <ItemSelection
                                  required
                                  size={fieldSize}
                                  label="Guardian Relationship"
                                  value={
                                    nominee.guardian_relationship
                                      ? relationshipOptions.find(
                                        (i) =>
                                          i.value ===
                                          nominee.guardian_relationship
                                      )
                                      : null
                                  }
                                  optionLabel="label"
                                  error={
                                    errorList &&
                                      errorList.nominees &&
                                      errorList.nominees[index]
                                      ? errorList.nominees[index]
                                        .guardian_relationship
                                      : false
                                  }
                                  options={relationshipOptions}
                                  style={{
                                    zIndex: 500,
                                    marginTop: 4,
                                    marginLeft: 0,
                                  }}
                                  formClass={classes.formAutoClass}
                                  selected={(value) => {
                                    handleChangeNominee(
                                      index,
                                      "guardian_relationship",
                                      value.value
                                    );
                                  }}
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "guardian_relationship"
                                    )
                                  }
                                />
                              ) : (
                                <FormControl variant={fieldVariant} fullWidth>
                                  <TextField
                                    label="Guardian Relationship"
                                    margin={fieldMargin}
                                    name="guardian_relationship"
                                    InputProps={{
                                      readOnly: disabled,
                                    }}
                                    value={
                                      nominee.guardian_relationship || "   "
                                    }
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    variant={fieldVariant}
                                  />
                                </FormControl>
                              )}
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              lg={12}
                              md={12}
                              spacing={1}
                              justify="flex-start"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  variant="h6"
                                  className={classes.title1}
                                  style={{ marginRight: 16 }}
                                >
                                  Guardian Address
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  disabled={
                                    lead.editable_fields &&
                                    !lead.editable_fields.lead.includes(
                                      "guardian_addr_same"
                                    )
                                  }
                                  control={
                                    <Checkbox
                                      checked={nominee.guardian_addr_same}
                                      name="guardian_addr_same"
                                      color="primary"
                                    />
                                  }
                                  label="Same as Permanent Address"
                                />
                              </Grid>
                            </Grid>
                            {!nominee.guardian_addr_same && (
                              <Grid
                                item
                                container
                                xs={12}
                                lg={12}
                                md={12}
                                spacing={1}
                                justify="flex-start"
                                alignItems="center"
                              >
                                <Grid item xs={12} lg={4} md={4}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="Address Line 1"
                                      margin={fieldMargin}
                                      name="guardian_addr1"
                                      InputProps={{
                                        readOnly: disabled,
                                      }}
                                      disabled={
                                        lead.editable_fields &&
                                        !lead.editable_fields.lead.includes(
                                          "guardian_addr1"
                                        )
                                      }
                                      value={
                                        nominee.guardian_addr1 ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4} md={4}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="Address Line 2"
                                      margin={fieldMargin}
                                      name="guardian_addr2"
                                      InputProps={{
                                        readOnly: disabled,
                                      }}
                                      disabled={
                                        lead.editable_fields &&
                                        !lead.editable_fields.lead.includes(
                                          "guardian_addr2"
                                        )
                                      }
                                      value={
                                        nominee.guardian_addr2 ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4} md={4}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="Address Line 3"
                                      margin={fieldMargin}
                                      name="guardian_addr3"
                                      InputProps={{
                                        readOnly: disabled,
                                      }}
                                      disabled={
                                        lead.editable_fields &&
                                        !lead.editable_fields.lead.includes(
                                          "guardian_addr3"
                                        )
                                      }
                                      value={
                                        nominee.guardian_addr3 ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={2} md={2}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="City"
                                      margin={fieldMargin}
                                      name="guardian_city"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      disabled={
                                        editMode
                                        //lead.editable_fields &&
                                        //!lead.editable_fields.lead.includes(
                                        //  "guardian_city"
                                        //)
                                      }
                                      value={
                                        nominee.guardian_city ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={2} md={2}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="State"
                                      margin={fieldMargin}
                                      name="guardian_state"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      disabled={
                                        editMode
                                        //lead.editable_fields &&
                                        //!lead.editable_fields.lead.includes(
                                        //  "guardian_state"
                                        //)
                                      }
                                      value={
                                        nominee.guardian_state ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={2} md={2}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="Country"
                                      margin={fieldMargin}
                                      name="guardian_country"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      disabled={
                                        editMode
                                        //lead.editable_fields &&
                                        //!lead.editable_fields.lead.includes(
                                        //  "guardian_country"
                                        //)
                                      }
                                      value={
                                        nominee.guardian_country ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={2} md={2}>
                                  <FormControl variant={fieldVariant} fullWidth>
                                    <TextField
                                      label="Pincode"
                                      margin={fieldMargin}
                                      name="guardian_pincode"
                                      InputProps={{
                                        readOnly: disabled,
                                      }}
                                      disabled={
                                        lead.editable_fields &&
                                        !lead.editable_fields.lead.includes(
                                          "guardian_pincode"
                                        )
                                      }
                                      value={
                                        nominee.guardian_pincode ||
                                        (editMode ? "" : "   ")
                                      }
                                      onChange={handleChange}
                                      aria-describedby="outlined-weight-helper-text"
                                      variant={fieldVariant}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        )}
                        {index < lead.nominees.length - 1 && (
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                backgroundColor: "#999",
                                marginTop: 8,
                                marginBottom: 8,
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Dependent
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2} md={2}>
                  <FormControl
                    component="fieldset"
                    disabled={
                      lead.editable_fields &&
                      !lead.editable_fields.lead.includes("dependent")
                    }
                  >
                    <FormLabel component="legend">
                      Dependent Application?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="dependent"
                      name="dependent"
                      value={lead.dependent}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="default" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio color="default" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {lead.dependent === 1 && (
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Relationship"
                        margin={fieldMargin}
                        name="relationship"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("relationship")
                        }
                        id="outlined-adornment-relationship"
                        value={
                          lead.relationship
                            ? lead.relationship.label || "  "
                            : "   "
                        }
                        error={errorList.relationship}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  {!editMode &&
                    !mobileView &&
                    segments &&
                    segments.relationship &&
                    segments.relationship.document_codes.some(
                      (d) =>
                        lead &&
                        lead.documents &&
                        lead.documents.findIndex(
                          (dd) => dd.document_code === d
                        ) !== -1
                    ) ? (
                    <BootstrapTooltip arrow title="Compare Proofs">
                      <IconButton
                        size="small"
                        onClick={() => showCompare("relationship")}
                      >
                        <CompareIcon />
                      </IconButton>
                    </BootstrapTooltip>
                  ) : undefined}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Bank details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset" disabled={editMode}>
                      <FormLabel component="legend">Penny dropped?</FormLabel>
                      <RadioGroup
                        row
                        aria-label="penny_drop"
                        name="penny_drop"
                        value={lead.penny_drop}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="default" />}
                          label="Yes"
                          disabled={editMode}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio color="default" />}
                          label="No"
                          disabled={editMode}
                        />
                      </RadioGroup>
                    </FormControl>

                    {!editMode &&
                      !mobileView &&
                      lead.penny_drop === 0 &&
                      segments &&
                      segments.bank &&
                      segments.bank.document_codes.some(
                        (d) =>
                          lead &&
                          lead.documents &&
                          lead.documents.findIndex(
                            (dd) => dd.document_code === d
                          ) !== -1
                      ) ? (
                      <BootstrapTooltip arrow title="Compare Proofs">
                        <IconButton
                          size="small"
                          onClick={() => showCompare("bank")}
                        >
                          <CompareIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    ) : undefined}
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset" disabled={editMode}>
                      <FormLabel component="legend">
                        Reverse Penny dropped?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="reverse_penny_drop"
                        name="reverse_penny_drop"
                        value={lead.reverse_penny_drop}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="default" />}
                          label="Yes"
                          disabled={editMode}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio color="default" />}
                          label="No"
                          disabled={editMode}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Account Type"
                        margin={fieldMargin}
                        name="bank_account_type"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes(
                            "bank_account_type"
                          )
                        }
                        id="outlined-adornment-bank_account_type"
                        value={
                          lead.bank_account_type || (editMode ? "" : "   ")
                        }
                        error={errorList.bank_account_type}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Account number"
                        margin={fieldMargin}
                        name="bank_account_no"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_account_no")
                        }
                        id="outlined-adornment-bank_account_no"
                        value={lead.bank_account_no || (editMode ? "" : "   ")}
                        error={errorList.bank_account_no}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="IFSC"
                        margin={fieldMargin}
                        name="bank_ifsc"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_ifsc")
                        }
                        id="outlined-adornment-bank_ifsc"
                        value={lead.bank_ifsc || (editMode ? "" : "   ")}
                        error={errorList.bank_ifsc}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Account holder name"
                        margin={fieldMargin}
                        name="account_holder_name"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes(
                            "account_holder_name"
                          )
                        }
                        id="outlined-adornment-account_holder_name1"
                        value={
                          lead.account_holder_name || (editMode ? "" : "   ")
                        }
                        error={errorList.account_holder_name}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="MICR"
                        margin={fieldMargin}
                        name="bank_micr"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_micr")
                        }
                        id="outlined-adornment-bank_micr"
                        value={lead.bank_micr || (editMode ? "" : "   ")}
                        error={errorList.bank_micr}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Bank name"
                        margin={fieldMargin}
                        name="bank_name"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_name")
                        }
                        id="outlined-adornment-bank_name"
                        value={lead.bank_name || (editMode ? "" : "   ")}
                        error={errorList.bank_name}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    md={12}
                    spacing={1}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" className={classes.title1}>
                        Bank Address
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 1"
                        margin={fieldMargin}
                        name="bank_addr1"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_addr1")
                        }
                        id="outlined-adornment-bank_addr1"
                        value={lead.bank_addr1 || (editMode ? "" : "   ")}
                        error={errorList.bank_addr1}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 2"
                        margin={fieldMargin}
                        name="bank_addr2"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_addr2")
                        }
                        id="outlined-adornment-bank_addr2"
                        value={lead.bank_addr2 || (editMode ? "" : "   ")}
                        error={errorList.bank_addr2}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Address Line 3"
                        margin={fieldMargin}
                        name="bank_addr3"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_addr3")
                        }
                        id="outlined-adornment-bank_addr3"
                        value={lead.bank_addr3 || (editMode ? "" : "   ")}
                        error={errorList.bank_addr3}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="City"
                        margin={fieldMargin}
                        name="bank_city"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("bank_city")
                        }
                        id="outlined-adornment-bank_city"
                        value={lead.bank_city || (editMode ? "" : "   ")}
                        error={errorList.bank_city}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="State"
                        margin={fieldMargin}
                        name="bank_state"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("bank_state")
                        }
                        id="outlined-adornment-bank_state"
                        value={lead.bank_state || (editMode ? "" : "   ")}
                        error={errorList.bank_state}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Country"
                        margin={fieldMargin}
                        name="bank_country"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={
                          editMode
                          //lead.editable_fields &&
                          //!lead.editable_fields.lead.includes("bank_country")
                        }
                        id="outlined-adornment-bank_country"
                        value={lead.bank_country || (editMode ? "" : "   ")}
                        error={errorList.bank_country}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={2} md={2}>
                    {editMode ? (
                      <ItemSelectionAsync
                        disabled={
                          lead.editable_fields &&
                          !lead.editable_fields.lead.includes("bank_pincode")
                        }
                        required
                        size={fieldSize}
                        label="Pincode"
                        value={
                          lead.bank_pincode
                            ? {
                              pincode: parseInt(lead.bank_pincode),
                              state: lead.bank_state,
                              city: lead.bank_city,
                            }
                            : null
                        }
                        optionLabel="pincode"
                        labelTemplate={(option) => option.pincode}
                        error={errorList.bank_pincode}
                        style={{ zIndex: 500, marginTop: 4, marginLeft: 0 }}
                        formClass={classes.formAutoClass}
                        selected={(value) => {
                          handleChangeObject({
                            bank_pincode: value.pincode,
                            bank_city: value.city,
                            bank_state: value.state,
                          });
                          setErrorList((e) => ({
                            ...e,
                            bank_pincode: !value,
                            bank_city: !value.city,
                            bank_state: !value.state,
                          }));
                        }}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon className={classes.icon} />
                              </Grid>
                              <Grid item xs>
                                <span style={{ fontWeight: 400 }}>
                                  {option.pincode}
                                </span>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`${option.city}, ${option.state}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    ) : (
                      <FormControl variant={fieldVariant} fullWidth>
                        <TextField
                          label="Pincode"
                          margin={fieldMargin}
                          name="bank_pincode"
                          InputProps={{
                            readOnly: disabled,
                          }}
                          id="outlined-adornment-bank_pincode"
                          value={lead.bank_pincode || (editMode ? "" : "   ")}
                          error={errorList.bank_pincode}
                          onChange={handleChange}
                          aria-describedby="outlined-weight-helper-text"
                          variant={fieldVariant}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={4} md={4} xl={3}>
                    {!editMode &&
                      state.user &&
                      ![USERTYPES.MD].includes(state.user.EmpUtype) && (
                        <BlueButton
                          disabled={lead && lead.penny_drop !== 1}
                          variant="contained"
                          onClick={viewPennyDropTransactions}
                        >
                          Penny drop Transactions
                        </BlueButton>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Segments
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl component="fieldset" disabled={editMode}>
                      <FormLabel component="legend">
                        Deriatives selected?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="selected_derivatives"
                        name="selected_derivatives"
                        value={lead.selected_derivatives}
                      >
                        <FormControlLabel
                          disabled={editMode || !disabled}
                          value={1}
                          control={<Radio color="default" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={editMode || !disabled}
                          value={0}
                          control={<Radio color="default" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {lead.selected_derivatives === 1 ? (
                    <Grid item xs={12} lg={3} md={3}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Disable derivatives?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="disable_derivatives"
                          name="disable_derivatives"
                          value={lead.disable_derivatives}
                          onChange={(e) => {
                            if (
                              !disabled &&
                              lead.editable_fields &&
                              lead.editable_fields.lead.includes(
                                "disable_derivatives"
                              )
                            ) {
                              handleChangeKV(
                                "disable_derivatives",
                                e.target.value === "true"
                              );
                            }
                          }}
                        >
                          <FormControlLabel
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes(
                                "disable_derivatives"
                              )
                            }
                            value={true}
                            control={<Radio color="default" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes(
                                "disable_derivatives"
                              )
                            }
                            value={false}
                            control={<Radio color="default" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {lead.segment &&
                    lead.segment.find(
                      (segment) =>
                        segment.segment_group_code === "equity" &&
                        segment.selected_options.includes("mf")
                    ) ? (
                    <Grid item xs={12} lg={4} md={4}>
                      <FormControl component="fieldset" disabled={editMode}>
                        <FormLabel component="legend">
                          Mutual funds Type?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="mf_type"
                          name="mf_type"
                          value={lead.mf_type}
                          onChange={(e) => {
                            if (
                              !disabled &&
                              lead.editable_fields &&
                              lead.editable_fields.lead.includes("mf_type")
                            ) {
                              handleChangeKV(
                                "mf_type",
                                e.target.value === "true"
                              );
                            }
                          }}
                        >
                          <FormControlLabel
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes("mf_type")
                            }
                            value="P"
                            control={<Radio color="default" />}
                            label="Without Demat"
                          />
                          <FormControlLabel
                            disabled={
                              lead.editable_fields &&
                              !lead.editable_fields.lead.includes("mf_type")
                            }
                            value="D"
                            control={<Radio color="default" />}
                            label="With Demat"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {masterSegments.map((segment, index) => {
                    let selection = lead.segment.find(
                      (s) => s.segment_group_code === segment.title_code
                    );
                    if (!selection) {
                      selection = {};
                      selection.selected_options = [];
                    }
                    return (
                      <Grid key={index} item xs={12} lg={12} md={12}>
                        <Grid item>
                          <Typography variant="h6" className={classes.title1}>
                            {segment.title_label}
                          </Typography>
                        </Grid>
                        <FormGroup row>
                          {segment.options.map((option, idx) => (
                            <FormControlLabel
                              disabled={editMode || !disabled}
                              key={idx}
                              control={
                                <Checkbox
                                  checked={
                                    selection &&
                                    selection.selected_options &&
                                    selection.selected_options.includes(
                                      option.code
                                    )
                                  }
                                  name="checkedB"
                                  color="default"
                                />
                              }
                              label={option.label}
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    Payment
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Invoice Id"
                        margin={fieldMargin}
                        name="invoice_id"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-invoice_id"
                        value={
                          lead.payment_details
                            ? lead.payment_details.invoice_id ||
                            (editMode ? "" : "   ")
                            : "   "
                        }
                        error={errorList.invoice_id}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Payment Id"
                        margin={fieldMargin}
                        name="payment_id"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-payment_id"
                        value={
                          lead.payment_details
                            ? lead.payment_details.payment_id ||
                            (editMode ? "" : "   ")
                            : "   "
                        }
                        error={errorList.payment_id}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Order Id"
                        margin={fieldMargin}
                        name="order_id"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-order_id"
                        value={
                          lead.payment_details
                            ? lead.payment_details.order_id ||
                            (editMode ? "" : "   ")
                            : "   "
                        }
                        error={errorList.order_id}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Payment Status"
                        margin={fieldMargin}
                        name="payment_status"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-payment_status"
                        value={
                          lead.payment_details
                            ? titleCase(
                              lead.payment_details.payment_status ||
                              (editMode ? "" : "   ")
                            )
                            : "   "
                        }
                        error={errorList.payment_status}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Request on"
                        margin={fieldMargin}
                        name="order_date"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-order_date"
                        value={
                          lead.payment_details &&
                            lead.payment_details.order_date
                            ? moment(
                              lead.payment_details.order_date,
                              format
                            ).format(format_display_date)
                            : "   "
                        }
                        error={errorList.order_date}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Paid on"
                        margin={fieldMargin}
                        name="payment_date"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-payment_date"
                        value={
                          lead.payment_details &&
                            lead.payment_details.payment_date
                            ? moment(
                              lead.payment_details.payment_date,
                              format
                            ).format(format_display_date)
                            : "   "
                        }
                        error={errorList.payment_date}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Mode of payment"
                        margin={fieldMargin}
                        name="payment_mode"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-payment_mode"
                        value={
                          lead.payment_details
                            ? lead.payment_details.payment_mode ||
                            (editMode ? "" : "   ")
                            : "   "
                        }
                        error={errorList.payment_mode}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={3}>
                    <FormControl variant={fieldVariant} fullWidth>
                      <TextField
                        label="Coupon code applied"
                        margin={fieldMargin}
                        name="coupon_code"
                        InputProps={{
                          readOnly: disabled,
                        }}
                        disabled={editMode || !disabled}
                        id="outlined-adornment-coupon_code"
                        value={
                          lead.payment_details
                            ? lead.payment_details.coupon_code ||
                            (editMode ? "" : "   ")
                            : "   "
                        }
                        error={errorList.coupon_code}
                        onChange={handleChange}
                        aria-describedby="outlined-weight-helper-text"
                        variant={fieldVariant}
                      />
                    </FormControl>
                  </Grid>
                  {lead.payment_details && lead.payment_details.notes && (
                    <Grid item container xs={12} lg={4} md={4}>
                      {Object.entries(lead.payment_details.notes).map(
                        (entry) => (
                          <Grid
                            key={entry[0]}
                            item
                            container
                            xs={12}
                            lg={12}
                            md={12}
                            style={{ padding: 5 }}
                          >
                            {entry[0] === "Grand Total" && (
                              <Grid item xs={12} lg={12}>
                                <Divider
                                  variant="middle"
                                  style={{
                                    backgroundColor: "black",
                                    marginBottom: 4,
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={6} lg={6} md={6}>
                              <Typography variant="subtitle2">
                                {entry[0]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6} md={6}>
                              <Typography variant="h6" align="right">
                                {entry[0] === "Discount" ? "-" : ""}
                                {formatNumber(entry[1] || 0)}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    IPV
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    lg={12}
                    md={12}
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <FormControl component="fieldset" disabled={editMode}>
                        <FormLabel component="legend">
                          IPV verified and not required?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="ipv"
                          name="ipv"
                          value={lead.ipv}
                        >
                          <FormControlLabel
                            disabled={editMode || !disabled}
                            value={1}
                            control={<Radio color="default" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={editMode || !disabled}
                            value={0}
                            control={<Radio color="default" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {lead.ipv === 0 &&
                      proofs.has("ipv") &&
                      proofs.get("ipv").otp && (
                        <Grid item>
                          <Typography variant="h5" className={classes.title1}>
                            OTP: {proofs.get("ipv").otp}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  {lead.ipv !== -1 &&
                    (proofs.has("ipv") || proofs.has("photo")) && (
                      <Grid item container xs={12} lg={12} md={12} spacing={1}>
                        {lead.ipv !== -1 && proofs.has("photo") && (
                          <Grid item xs={12} lg={4} md={4}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="h5"
                                className={classes.title1}
                              >
                                Photo
                              </Typography>
                              {/* <div className={classes.grow} /> */}
                              {!editMode && (
                                <BootstrapTooltip
                                  arrow
                                  title={
                                    lead &&
                                      ![
                                        STATUS.COMPLETED,
                                        STATUS.ESIGN_PENDING,
                                        STATUS.REJECTED,
                                      ].includes(lead.status)
                                      ? ""
                                      : "Request retake of Photo"
                                  }
                                >
                                  <span>
                                    <IconButton
                                      disabled={
                                        lead &&
                                        ![
                                          STATUS.COMPLETED,
                                          STATUS.ESIGN_PENDING,
                                          STATUS.REJECTED,
                                        ].includes(lead.status)
                                      }
                                      size="small"
                                      style={{ marginLeft: 15 }}
                                      onClick={action("photo")}
                                    >
                                      <PhotoCameraIcon />
                                    </IconButton>
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </div>
                            <img
                              height="240"
                              src={proofs.get("photo").url}
                              alt={proofs.get("photo").document_code}
                            />
                            {lead.retake_photo === 0 &&
                              [USERTYPES.KYC, USERTYPES.BRANCH].includes(
                                state.user.EmpUtype
                              ) &&
                              lead.photo_link ? (
                              <div style={{ marginTop: 12 }}>
                                <Typography
                                  variant="caption"
                                  component="p"
                                  style={{
                                    border: "1px dotted black",
                                    padding: 4,
                                    borderRadius: 4,
                                    width: 200,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                  }}
                                >
                                  {lead.photo_link}
                                </Typography>

                                <FileCopyIcon
                                  style={{ marginBottom: 2, marginLeft: 8 }}
                                  fontSize="small"
                                  onClick={() => {
                                    if ("clipboard" in navigator) {
                                      navigator.clipboard.writeText(
                                        lead.photo_link
                                      );
                                    } else {
                                      document.execCommand(
                                        "copy",
                                        true,
                                        lead.photo_link
                                      );
                                    }
                                    showSnackBar("Copied", "success");
                                  }}
                                />
                              </div>
                            ) : null}
                          </Grid>
                        )}
                        {lead.ipv === 0 && proofs.has("ipv") && (
                          <Grid item xs={12} lg={4} md={4}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="h5"
                                className={classes.title1}
                              >
                                Video
                              </Typography>
                              {/* <div className={classes.grow} /> */}
                              {!editMode && (
                                <BootstrapTooltip
                                  arrow
                                  title={
                                    lead &&
                                      ![
                                        STATUS.COMPLETED,
                                        STATUS.ESIGN_PENDING,
                                        STATUS.REJECTED,
                                      ].includes(lead.status)
                                      ? ""
                                      : "Request retake of IPV"
                                  }
                                >
                                  <span>
                                    <IconButton
                                      disabled={
                                        lead &&
                                        ![
                                          STATUS.COMPLETED,
                                          STATUS.ESIGN_PENDING,
                                          STATUS.REJECTED,
                                        ].includes(lead.status)
                                      }
                                      size="small"
                                      style={{ marginLeft: 15 }}
                                      onClick={action("ipv")}
                                    >
                                      <VideocamIcon />
                                    </IconButton>
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </div>
                            <video height="240" controls>
                              <source
                                src={proofs.get("ipv").url}
                                type={proofs.get("ipv").type}
                              />
                            </video>
                            {lead.retake_ipv === 0 &&
                              [USERTYPES.KYC, USERTYPES.BRANCH].includes(
                                state.user.EmpUtype
                              ) &&
                              lead.ipv_link ? (
                              <div style={{ marginTop: 12 }}>
                                <Typography
                                  variant="caption"
                                  component="p"
                                  style={{
                                    border: "1px dotted black",
                                    padding: 4,
                                    borderRadius: 4,
                                    width: 200,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                  }}
                                >
                                  {lead.ipv_link}
                                </Typography>
                                <FileCopyIcon
                                  style={{ marginBottom: 2, marginLeft: 8 }}
                                  fontSize="small"
                                  onClick={() => {
                                    if ("clipboard" in navigator) {
                                      navigator.clipboard.writeText(
                                        lead.ipv_link
                                      );
                                    } else {
                                      document.execCommand(
                                        "copy",
                                        true,
                                        lead.ipv_link
                                      );
                                    }
                                    showSnackBar("Copied", "success");
                                  }}
                                />
                              </div>
                            ) : null}
                          </Grid>
                        )}
                        {((lead.ipv === 0 &&
                          proofs.has("ipv") &&
                          proofs.get("ipv").location) ||
                          (proofs.has("photo") &&
                            proofs.get("photo").location)) && <Location />}
                      </Grid>
                    )}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
            {
              <Grid
                item
                xs={12}
                lg={12}
                container
                justify="flex-start"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item xs={12} lg={3} md={3}>
                  <Typography variant="h5" className={classes.title1}>
                    E-Sign
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item>
                      <FormControl component="fieldset" disabled={editMode}>
                        <FormLabel component="legend">E-Signed?</FormLabel>
                        <RadioGroup
                          row
                          aria-label="aadhaar_mobile_linked"
                          name="aadhaar_mobile_linked"
                          value={lead.aadhaar_mobile_linked}
                          onChange={(e) => editMode && handleChange(e)}
                        >
                          <FormControlLabel
                            disabled={editMode || !disabled}
                            value={1}
                            control={<Radio color="default" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={editMode || !disabled}
                            value={0}
                            control={<Radio color="default" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {proofs.has("application_form") && (
                      <Grid item>
                        <Button
                          style={{ color: blue[500], marginTop: 8 }}
                          onClick={() =>
                            openLink(proofs.get("application_form").url)
                          }
                        >
                          View Application Form
                        </Button>
                      </Grid>
                    )}
                    {lead.options && lead.options.esign && (
                      <Grid item>
                        <BlueButton
                          variant="contained"
                          onClick={() => requestOption("esign")}
                        >
                          Get E-sign
                        </BlueButton>
                      </Grid>
                    )}
                    {lead.status === STATUS.ESIGN_REQUESTED &&
                      [USERTYPES.KYC, USERTYPES.BRANCH].includes(
                        state.user.EmpUtype
                      ) &&
                      lead.esign_url && (
                        <Grid item>
                          <div style={{ marginTop: 12 }}>
                            <Typography
                              variant="caption"
                              component="p"
                              style={{
                                border: "1px dotted black",
                                padding: 4,
                                borderRadius: 4,
                                width: 200,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                              }}
                            >
                              {lead.esign_url}
                            </Typography>

                            <FileCopyIcon
                              style={{ marginBottom: 2, marginLeft: 8 }}
                              fontSize="small"
                              onClick={() => {
                                if ("clipboard" in navigator) {
                                  navigator.clipboard.writeText(lead.esign_url);
                                } else {
                                  document.execCommand(
                                    "copy",
                                    true,
                                    lead.esign_url
                                  );
                                }
                                showSnackBar("Copied", "success");
                              }}
                            />
                          </div>
                        </Grid>
                      )}
                  </Grid>
                  {lead.aadhaar_mobile_linked === 0 &&
                    ![
                      STATUS.ESIGN_REQUESTED,
                      STATUS.ONGOING,
                      STATUS.APPROVED,
                      STATUS.ACTIVATED,
                    ].includes(lead.status) && (
                      <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xs={12} lg={8} md={8}>
                          <FormControl variant={fieldVariant} fullWidth>
                            <TextField
                              label="Tracking Details"
                              margin={fieldMargin}
                              style={{ marginTop: 4 }}
                              name="tracking_details"
                              id="outlined-adornment-tracking_details"
                              value={lead.tracking_details || ""}
                              error={errorList.tracking_details}
                              onChange={handleChange}
                              aria-describedby="outlined-weight-helper-text"
                              variant={fieldVariant}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          {!editMode && (
                            <Button variant="outlined" onClick={updateTracking}>
                              Update
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            }
          </Grid>
          <MyDialog
            title="Penny Drop Transactions"
            maxWidth={"md"}
            content={
              <div>
                <Grid container spacing={2}>
                  {!txnLoading &&
                    transactions.map((txn, i) => (
                      <Grid key={i} item xs={12} lg={6}>
                        <Card className={classes.card}>
                          <CardHeader
                            title={
                              <>
                                {txn.verified ? (
                                  <GreenTick
                                    fontSize="small"
                                    style={{ marginRight: 8 }}
                                  />
                                ) : (
                                  <RedCancelIcon
                                    fontSize="small"
                                    style={{ marginRight: 8 }}
                                  />
                                )}
                                <span style={{ verticalAlign: "top" }}>
                                  Pennydrop ID: {txn.pennydrop_id}
                                </span>
                              </>
                            }
                            subheader={`${txn.verified ? "Verified" : "Transacted"
                              } At ${moment(txn.verified_at, format).format(
                                format_display_withtime
                              )}`}
                            subheaderTypographyProps={{ variant: "caption" }}
                          />
                          <CardContent>
                            <Grid container justify="space-between">
                              <Grid item xs={12} lg={6}>
                                Account Number
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {txn.account_number || "-"}
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                IFSC
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {txn.ifsc || "-"}
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                Name with Bank
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {txn.name_with_bank || "-"}
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                Amount
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                {formatNumber(txn.amount || 0)}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  {txnLoading && (
                    <Grid item xs={12} lg={12}>
                      <div style={{ height: 200 }}>
                        <CircularProgress
                          style={{
                            position: "relative",
                            top: "30%",
                            left: "45%",
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            }
            open={showPennydropTransactions}
            close={() => {
              setTransactions([]);
              setShowPennydropTransactions(false);
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ViewLeadDialog.prototype = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
